import React from 'react';
import { Banner } from '@olo-web/components/atoms/Banner';

export const OrderingUnavailableAlert = ({ isForMobile = false }: { isForMobile?: boolean }) => (
  <Banner
    title="Ordering currently unavailable"
    description="Check back soon!"
    status="warning"
    display={isForMobile ? 'block' : 'none'}
  />
);
