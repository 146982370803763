import React, { useMemo } from 'react';
import { HStack, IconButton, StackProps, useMediaQuery } from '@chakra-ui/react';
import { useSensitiveScrollNavBar } from '../../..';
import { MenuSearchInput } from '@molecules/MenuSearchInput/MenuSearchInput.ui';
import { CustomerOrLogin } from '@olo-web/components/molecules/CustomerOrLogin';
import { Search } from '@olo-web/assets/icons/Search.ui';
import { BagButtonAndPopover } from '@olo-web/components/templates/Bag';
import { DisruptionIconButton } from '@olo-web/components/molecules/DisruptionIconButton/DisruptionIconButton';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

interface IMenuHeaderButtonsDesktop extends StackProps {
  show?: boolean;
  isInNavBar?: boolean;
}

export const MenuHeaderButtonsDesktop = (props: IMenuHeaderButtonsDesktop) => {
  const { show, isInNavBar, ...rest } = props;
  const { showSearch, toggleSearch, handleBlur, isLg } = useSensitiveScrollNavBar({ show });
  const [isSmallerThanMed] = useMediaQuery(`(max-width: ${breakpointsObject?.md})`);

  const bProps = useMemo(() => {
    return {
      minW: '105px',
      mr: isInNavBar && isLg ? 0 : isLg ? 0 : 2,
      ...(!isSmallerThanMed && { 'data-testid': 'login-button' }),
    };
  }, [isInNavBar, isLg, isSmallerThanMed]);

  return (
    <HStack alignItems="center" justifyContent="flex-end" zIndex="2" {...rest}>
      <DisruptionIconButton />
      {showSearch && show ? (
        <MenuSearchInput
          autoFocus
          onBlur={handleBlur}
          maxW="188px"
          showClear
          _focus={{
            boxShadow: 'none',
            borderColor: 'black',
            borderBottomWidth: '2px',
          }}
          {...(!isSmallerThanMed && { 'data-testid': 'search-input' })}
        />
      ) : (
        <IconButton
          aria-label="Open search input"
          borderRadius="22px"
          icon={<Search h={5} w={5} />}
          onClick={toggleSearch}
          size="md"
          variant="ghost"
          colorScheme="blackAlpha"
          color="black"
          {...(show && { 'data-testid': 'search-button' })}
        />
      )}
      {!isInNavBar && (
        <CustomerOrLogin
          buttonProps={bProps}
          avatarMenuButtonProps={{
            ...(!isInNavBar && { 'data-testid': 'user-avatar-button' }),
          }}
        />
      )}
      {!isLg && <BagButtonAndPopover show={show} />}
    </HStack>
  );
};
