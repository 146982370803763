import React from 'react';
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { EOrderTypes } from '@olo-web/types/enums';
import { useOrderType, useDeliveryModal } from '@olo-web/utils/common/hooks';
import { useModalDispatch, useDeliveryAddressState } from '@olo-web/client-state';
import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';
import { EModalTypes, EModalSubTypes } from '@olo-web/types/enums';

export const MenuHeaderLocationButton = () => {
  const orderType = useOrderType();
  const { data: merchant } = useMerchant();
  const { data: merchantGroup } = useMerchantGroup();
  const { address } = useDeliveryAddressState();
  const hasDeliveryAddress = address?.address1;
  const modalDispatch = useModalDispatch();
  const { openDeliveryModal } = useDeliveryModal();

  const showChangeButton = !(
    orderType === EOrderTypes.PICKUP && merchantGroup?.merchants?.length <= 1
  );

  const handleAddressClick = (e) => {
    if (
      e.target.getAttribute('Aria-label') === 'Close' ||
      e.target.classList.contains('chakra-icon') ||
      e.target.tagName === 'path'
    ) {
      return;
    }
    if (orderType === EOrderTypes.PICKUP) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.MERCHANT_LOCATION,
          subType: EModalSubTypes.LOCATION.MAP,
        },
      });
    } else {
      openDeliveryModal();
    }
  };

  return (
    <Flex
      onClick={(e) => showChangeButton && handleAddressClick(e)}
      w="100%"
      position="relative"
      mb={3}
    >
      <Text fontWeight="semibold" mr={2} fontSize="sm">
        {orderType === EOrderTypes.PICKUP ? 'From:' : hasDeliveryAddress ? 'To:' : null}
      </Text>
      <Stack
        w="100%"
        alignItems="flex-start"
        spacing={{ base: 0, md: 3 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <Text data-testid="location-address" fontSize="sm">
          {orderType === EOrderTypes.PICKUP
            ? merchant?.locationString
            : hasDeliveryAddress
            ? `${address?.address1}, ${address?.address2 ? address?.address2 + ',' : ''} ${
                address?.city
              }, ${address?.state} ${address?.zip}`
            : ''}
        </Text>
        {showChangeButton && (
          <Button
            fontSize="sm"
            variant="link"
            fontWeight="semibold"
            color="primary.350"
            textDecor="underline"
            _hover={{
              cursor: 'pointer',
              color: 'primary.400',
            }}
            data-testid={
              orderType === EOrderTypes.PICKUP
                ? 'change-locations-button'
                : hasDeliveryAddress
                ? 'change-delivery-address-button'
                : 'add-delivery-address-button'
            }
          >
            {orderType === EOrderTypes.PICKUP
              ? 'Change location'
              : hasDeliveryAddress
              ? 'Change address'
              : 'Add delivery address'}
          </Button>
        )}
      </Stack>
    </Flex>
  );
};
