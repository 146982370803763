import React, { useLayoutEffect, useRef, useEffect } from 'react';
import {
  Flex,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { useBagBar, useHasUnsentItems } from '../hooks';
import { AnimatedNumberDisplay } from '@olo-web/components/atoms/AnimatedNumberDisplay';
import { BagBody } from '../BagBody';
import { BagIconForButton } from '@molecules/BagIconForButton';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { useModalState } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';

export const BagButtonAndDrawer = () => {
  const {
    open,
    handleClose,
    productCount,
    handleToggle,
    handleOpen,
    goToCheckout,
    order,
    orderLoading,
    total,
    routerLoading,
    totalQuantity,
    isValidatingOrder,
    bagTitle,
    bagButtonText,
    isSendingItems,
  } = useBagBar();
  const isDineIn = useIsDineIn();
  const { data: merchant } = useMerchant();
  const hasUnsentItems = useHasUnsentItems();
  const hideFooterButton = isDineIn && merchant?.dineinPayLaterEnabled && hasUnsentItems;
  const buttonProps = { goToCheckout, isValidatingOrder, routerLoading };
  const modalState = useModalState();
  const refHeader = useRef(null);

  /*
   * This code adds the necessary attributes for the VoiceOver to read alerts.
   * In the chakra modal it is not possible to set the required attributes.
   * The modal has the highest priority for VoiceOver, so other notifications are ignored.
   */
  useLayoutEffect(() => {
    const drawerElement = refHeader.current?.closest('#chakra-modal-bag-drawer');

    if (drawerElement) {
      drawerElement.setAttribute('aria-hidden', `${open}`);
      drawerElement.setAttribute('aria-modal', `${!open}`);
    }
  }, [open]);

  // TODO: create Modal component with modal state instead of separate <Drawer /> after it this useEffect should be removed
  useEffect(() => {
    // close BagDrawer before opening ApplyOfferDrawer
    // since ApplyOfferDrawer has problems with scrolling if BagDrawer is open
    if (modalState?.modalKey === EModalTypes.APPLY_OFFERS) {
      handleClose();
    }
  }, [modalState?.modalKey, handleClose]);

  if (productCount <= 0 || !order) return null;

  return (
    <>
      <Flex
        pos="relative"
        flex="1 1 auto"
        bottom={0}
        right={0}
        left={0}
        justify="flex-end"
        align="flex-end"
        zIndex="3"
        bgGradient="linear(to-b, rgba(255,255,255,0), white 45%)"
      >
        <Button
          variant="solid"
          colorScheme="primary"
          fontSize="lg"
          mx={2}
          mb={2}
          w="100%"
          onClick={handleOpen}
          aria-label={`View your ${isDineIn ? 'order' : 'bag'}, ${productCount} items, $${total}`}
        >
          <Flex w="100%" align="center" justify="space-between" data-testid="your-bag-button">
            <Flex align="center">
              <BagIconForButton
                open={open}
                productCount={productCount}
                totalQuantity={totalQuantity}
                buttonConfig={{ h: 7, w: 7, mr: 2 }}
              />
              {`View your ${isDineIn ? 'order' : 'bag'}`}
            </Flex>
            <AnimatedNumberDisplay toFixed={2} leadingChar="$" value={total} />
          </Flex>
        </Button>
      </Flex>
      <Drawer
        placement="bottom"
        size="full"
        isOpen={open}
        onClose={handleClose}
        isFullHeight
        autoFocus={false}
        preserveScrollBarGap={false}
        useInert={false}
        id="bag-drawer"
      >
        <DrawerOverlay />
        <DrawerContent
          backgroundColor={productCount ? '#F7F7FA' : 'white'}
          maxH="calc(100% - env(safe-area-inset-top))"
        >
          <DrawerHeader
            backgroundColor="white"
            pt={4}
            px={4}
            pb={4}
            style={{ backdropFilter: 'blur(4px)' }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            ref={refHeader}
          >
            <h3>{bagTitle}</h3>
            <DrawerCloseButton position="static" h={6} />
          </DrawerHeader>
          <DrawerBody p={0}>
            <BagBody
              order={order}
              onAddItemsClick={handleToggle}
              isLoading={orderLoading}
              showSendItemsButton={hideFooterButton}
              buttonProps={buttonProps}
              isSendingItems={isSendingItems}
              handleOpenBagDrawer={handleOpen}
            />
          </DrawerBody>
          {hideFooterButton || isSendingItems ? null : (
            <DrawerFooter>
              <Button
                w="100%"
                colorScheme="primary"
                size="lg"
                onClick={goToCheckout}
                isLoading={isValidatingOrder || routerLoading}
                data-testid={`${bagButtonText}-button`}
              >
                {bagButtonText}
              </Button>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};
