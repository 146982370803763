import React from 'react';
import { Container, ContainerProps } from '@chakra-ui/react';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

export const WideScreenContainer = (props: ContainerProps) => {
  const { children, ...rest } = props;
  return (
    <Container maxW={breakpointsObject.xl} p={0} w="100%" {...rest}>
      {children}
    </Container>
  );
};
