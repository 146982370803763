import React, { memo, useState, useEffect } from 'react';
import {
  Button,
  Icon,
  IconButton,
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  VStack,
  DrawerCloseButton,
  Menu,
  MenuItem,
  MenuList,
  MenuButton,
  Portal,
  Text,
  StackDivider,
  Box,
} from '@chakra-ui/react';
import { List, X } from 'react-feather';
import { ConditionalWrapper } from '@olo-web/components/atoms/Wrappers/ConditionalWrapper';
import { useMediaQuery } from '@chakra-ui/react';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

type TListIconButton = {
  isDisabled?: boolean;
  onToggle?: () => void;
};

const title = 'Menu categories';
let selectedCategory = '';

const iconButtonStyles = {
  size: 'md',
  borderRadius: '22px',
  border: 'none',
  variant: 'ghost',
  color: 'black',
  'aria-label': 'Open Menu Group Selector List',
};

const TriggerIcon = ({ as }) => <Icon as={as} h={5} w={5} />;

const ListIconButton = ({ isDisabled, onToggle }: TListIconButton) => (
  <IconButton
    icon={<TriggerIcon as={List} />}
    disabled={isDisabled}
    onClick={onToggle}
    {...iconButtonStyles}
  />
);

function MenuGroupListMenuComponent({
  groupPreviews = [],
  onSelect,
}: {
  groupPreviews: IRibbonItem[];
  onSelect: (groupPreview: IRibbonItem) => void;
}): JSX.Element {
  const [isSmallerThanMed, setIsSmallerThanMed] = useState(true);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [smallerThanMed] = useMediaQuery(`(max-width: ${breakpointsObject?.md})`);

  useEffect(() => {
    setIsSmallerThanMed(smallerThanMed);
  }, [smallerThanMed]);

  const handleSelect = (group) => {
    onSelect(group);
    onClose();
    selectedCategory = group.value;
  };

  const isDisabled = !groupPreviews.length;
  return (
    <ConditionalWrapper
      condition={isSmallerThanMed}
      wrapper={() => (
        <>
          <ListIconButton isDisabled={isDisabled} onToggle={onToggle} />
          <Drawer placement="bottom" isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent borderRadius="16px 16px 0 0" maxH="90%">
              <DrawerCloseButton />
              <DrawerHeader>{title}</DrawerHeader>
              <DrawerBody>
                <VStack
                  alignItems="flex-start"
                  divider={
                    <StackDivider alignSelf="center" w="100%" borderColor="blackAlpha.200" />
                  }
                >
                  {groupPreviews.map((g) => (
                    <Button
                      onClick={() => handleSelect(g)}
                      variant="tertiary"
                      p={0}
                      color="blackAlpha.500"
                      fontWeight={selectedCategory === g.value ? '600' : '400'}
                      key={g.value}
                      width="100%"
                      justifyContent="flex-start"
                      _focus={{
                        outline: '2px solid',
                        outlineColor: 'primary.350',
                        outlineOffset: '2px',
                      }}
                    >
                      {g.label}
                      {selectedCategory === g.value && (
                        <Box
                          top={0}
                          bottom={0}
                          left={-9}
                          pos="absolute"
                          w={4}
                          borderRadius="md"
                          bg="primary.350"
                          mt="-10px"
                          mb="-10px"
                        />
                      )}
                    </Button>
                  ))}
                </VStack>
              </DrawerBody>
              <DrawerFooter>
                <Button
                  w="100%"
                  colorScheme="primary"
                  size="lg"
                  onClick={onClose}
                  borderRadius="4px"
                  _focus={{
                    outline: '2px solid',
                    outlineColor: 'primary.350',
                    outlineOffset: '2px',
                  }}
                >
                  Close
                </Button>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        </>
      )}
      falseWrapper={(children) => (
        <Menu placement="bottom-end" colorScheme="blackAlpha">
          {({ isOpen }) => (
            <>
              <MenuButton
                as={IconButton}
                icon={<TriggerIcon as={isOpen ? X : List} />}
                {...iconButtonStyles}
                data-testid="menu-item-group-dropdown-button"
              />
              <Portal>
                <MenuList
                  data-testid="menu-group-dropdown-list"
                  maxH="65vh"
                  overflowY="scroll"
                  right={{
                    base: 0,
                    lg: '1000px',
                  }}
                  maxW="500px"
                >
                  {children}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      )}
    >
      <VStack alignItems="flex-start">
        {groupPreviews.map((g) => (
          <ConditionalWrapper
            key={g.value}
            condition={!isSmallerThanMed}
            wrapper={(children) => (
              <MenuItem
                onClick={() => handleSelect(g)}
                _hover={{ bg: 'blackAlpha.50' }}
                _focus={{
                  bg: 'blackAlpha.50',
                  outline: '2px solid',
                  outlineColor: 'primary.350',
                  outlineOffset: '2px',
                }}
                data-testid="menu-item-group-in-dropdown"
              >
                {children}
              </MenuItem>
            )}
            falseWrapper={(children) => (
              <Button onClick={() => handleSelect(g)} variant="tertiary" colorScheme="blackAlpha">
                {children}
              </Button>
            )}
          >
            <Text noOfLines={1}>{g.label}</Text>
          </ConditionalWrapper>
        ))}
      </VStack>
    </ConditionalWrapper>
  );
}

export const MenuGroupListMenu = memo(MenuGroupListMenuComponent);
