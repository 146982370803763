import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const Search = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M10.6401 10.6401C11.662 9.61822 12.2941 8.20645 12.2941 6.64706C12.2941 3.52827 9.76584 1 6.64706 1C3.52827 1 1 3.52827 1 6.64706C1 9.76584 3.52827 12.2941 6.64706 12.2941C8.20645 12.2941 9.61822 11.662 10.6401 10.6401ZM10.6401 10.6401L17 17"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
