import React from 'react';
import { TabList, Tabs, Tab, Box, Spinner } from '@chakra-ui/react';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { AnimateSharedLayout } from 'framer-motion';

type TPillTabs = {
  value: any;
  items: { label: JSX.Element | string; value: any; disabled?: boolean }[];
  onChange: (value: any) => void;
  width?: string | Record<string, string>;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export const PillTabs = ({
  items,
  value,
  onChange,
  width = '100%',
  isDisabled,
  isLoading,
}: TPillTabs) => {
  const handleChange = (i: number): void => {
    onChange(items[i].value);
  };

  const tabW = `${100 / (items?.length || 1)}%`;

  return (
    <Tabs
      isFitted
      variant="unstyled"
      onChange={handleChange}
      bg="blackAlpha.100"
      borderRadius="22px"
      w={width}
      data-testid="toggle"
      index={items?.findIndex((i) => i.value === value)}
      isManual
    >
      <TabList borderColor="blackAlpha.100">
        <AnimateSharedLayout>
          {items.map((item, index) => {
            const isSelected = value === item.value;
            const tabColor = isDisabled
              ? 'blackAlpha.300'
              : isSelected
              ? 'white'
              : 'blackAlpha.900';

            return (
              <Box
                key={item.value + index}
                pos="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                w={tabW}
              >
                <Tab
                  pos="relative"
                  zIndex={2}
                  color={tabColor}
                  fontWeight={isSelected ? '600' : '400'}
                  isDisabled={isDisabled || isLoading || item.disabled}
                  cursor={isDisabled || isLoading || item.disabled ? 'not-allowed' : 'pointer'}
                >
                  {item.label}
                  {isLoading && !isSelected ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      pos="absolute"
                      top={0}
                      right={0}
                      left={0}
                      bottom={0}
                      bg="rgba(255,255,255,0.5)"
                      filter="blur(1px)"
                    >
                      <Spinner />
                    </Box>
                  ) : null}
                </Tab>
                {isSelected && (
                  <MotionBox
                    layoutId="tab-pill"
                    bg={isDisabled ? 'blackAlpha.200' : 'primary.350'}
                    borderColor="gray.200"
                    h="44px"
                    borderRadius="22px"
                    borderStyle="solid"
                    borderWidth="4px"
                    pos="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  />
                )}
              </Box>
            );
          })}
        </AnimateSharedLayout>
      </TabList>
    </Tabs>
  );
};
