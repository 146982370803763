import React from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { Banner } from '@olo-web/components/atoms/Banner';
import { useIsMinOrderAmountUnmet } from '@olo-web/domain/orders/hooks/useIsMinOrderAmountUnmet';
import { useOrderType } from '@olo-web/utils/common/hooks';

export const DeliveryMinimumAmountAlert = () => {
  const isMinOrderAmountUnmet = useIsMinOrderAmountUnmet();
  const { data: merchant } = useMerchant();
  const orderType = useOrderType();

  if (!isMinOrderAmountUnmet) return null;

  return (
    <Banner
      status="info"
      description={`Minimum delivery order amount not met (${formatPrice(
        Number(merchant?.orderTypes?.[orderType]?.minOrderAmount || 0)
      )}). Please add more items or switch to pickup.`}
    />
  );
};
