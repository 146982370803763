import React from 'react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { useIsOverMaxOrderAmount } from '@olo-web/domain/orders/hooks/useIsOverMaxOrderAmount';
import { Text } from '@chakra-ui/react';
import { Banner } from '@olo-web/components/atoms/Banner';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useEffect } from 'react';
import { useSendEvent } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames } from '@olo-web/types/enums';

type T = {
  amount: string;
};

const Description = ({ amount }: T) => {
  return (
    <Text>
      Sorry, but we can only accept online orders of <b>{formatPrice(amount)} or less</b>. Please
      give us a call to place a large order.
    </Text>
  );
};

export const OverMaxOrderAmountInlineAlert = () => {
  const isOverMaxOrderAmount = useIsOverMaxOrderAmount();
  const { sendEvent } = useSendEvent();
  const { data: merchant } = useMerchant();
  const phone = merchant?.phone;

  useEffect(() => {
    if (isOverMaxOrderAmount)
      sendEvent(EAnalyticsEventNames.MAX_ORDER_AMOUNT, {
        googleAnalytics: {
          maxOrderAmount: merchant?.maxOrderAmount,
        },
      });
  }, [isOverMaxOrderAmount, sendEvent, merchant?.maxOrderAmount]);

  if (!isOverMaxOrderAmount) return null;

  return (
    <Banner
      status="info"
      description={<Description amount={merchant?.maxOrderAmount ?? ''} />}
      actionText={
        phone ? `Call us at ${parsePhoneNumberFromString(phone, 'US')?.formatNational()}` : ''
      }
      actionHref={phone ? `tel:${phone}` : undefined}
    />
  );
};
