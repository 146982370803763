import { useScreenSizeState } from '@olo-web/client-state';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { useMenuDispatch, useMenuState } from '@olo-web/client-state';
import { useMerchantGroup } from '@domain/merchants/queries/useMerchantGroup';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { TScrollSensitiveNavBar } from '..';

export const styles = {
  bg: 'white',
  h: { sm: '98px', md: '64px' },
  pos: 'fixed',
  paddingTop: 'env(safe-area-inset-top)',
  top: 0,
  left: 0,
  right: 0,
  w: { base: '100%', lg: 'calc(100% - 361px)' },
  display: 'flex',
  borderColor: 'blackAlpha.100',
  flexDir: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
} as const;

export const useSensitiveScrollNavBar = ({ show, showClear }: TScrollSensitiveNavBar) => {
  const { data: group, isLoading: groupLoading } = useMerchantGroup();
  const [showSearch, setShowSearch] = useState(false);
  const { searchText } = useMenuState();
  const menuDispatch = useMenuDispatch();

  const { data: order, isLoading: orderLoading } = useOrder();
  const { isMd, isLg, isMdDown } = useScreenSizeState();

  useEffect(() => {
    if (searchText?.length) setShowSearch(true);
  }, [searchText]);

  const variants = useMemo(() => {
    return {
      visible: {
        opacity: 1,
        zIndex: 5,
        transition: {
          duration: isMdDown ? 0.5 : 0.25,
        },
      },
      hidden: {
        opacity: 0,
        zIndex: -1,
        duration: 0.15,
      },
    };
  }, [isMdDown]);

  const toggleSearch = useCallback(() => {
    if (searchText && showClear) {
      menuDispatch({ type: 'UPDATE_SEARCH', payload: '' });
    }
    if (!searchText || showClear || !showSearch) {
      setShowSearch((prev) => !prev);
    }
  }, [searchText, showClear, showSearch, menuDispatch]);

  const showNavBar = useMemo(
    () => Boolean(show || (isMdDown && searchText)),
    [show, searchText, isMdDown]
  );

  const handleBlur = useCallback(() => {
    if (searchText?.length) return null;
    toggleSearch();
  }, [searchText, toggleSearch]);

  return {
    group,
    groupLoading,
    handleBlur,
    isMd,
    isLg,
    styles,
    order,
    orderLoading,
    showNavBar,
    showSearch,
    toggleSearch,
    variants,
  };
};
