import React, { FC, Fragment } from 'react';
import { MenuSearchInput } from '@molecules/MenuSearchInput/MenuSearchInput.ui';
import { Flex, HStack, Text, IconButton, Icon, Button, Box, useMediaQuery } from '@chakra-ui/react';
import Image from 'next/image';
import { X } from 'react-feather';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import {
  useEstimatedTimeText,
  useIsThirdPartyDelivery,
  useOrderTypeDisplayName,
} from '@olo-web/utils/common/hooks';
import { useSensitiveScrollNavBar, TScrollSensitiveNavBar } from '../../../..';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import { Search } from '@olo-web/assets/icons/Search.ui';
import { useMerchantImages } from '@domain/merchants/hooks/useMerchantImages';
import { useEstimateDelivery } from '@olo-web/domain/deliveryService/queries/useEstimateDelivery';
import { breakpointsObject } from '@olo-web/theme/breakpoints';

export const NavBarDetailsMobile: FC<TScrollSensitiveNavBar> = ({ show }) => {
  const { order, showSearch, toggleSearch, handleBlur } = useSensitiveScrollNavBar({
    show,
    showClear: true,
  });
  const isDineIn = useIsDineIn();
  const orderTypeDisplayName = useOrderTypeDisplayName();
  const { logoUrl, logoAlt } = useMerchantImages();
  const modalDispatch = useModalDispatch();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { data: deliveryEstimate } = useEstimateDelivery();
  const estimatedTimeText = useEstimatedTimeText(
    isThirdPartyDelivery ? deliveryEstimate?.deliveryTime : order?.orderDateTime
  );
  const [isSmallerThanMed] = useMediaQuery(`(max-width: ${breakpointsObject?.md})`);

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: { modalKey: EModalTypes.ORDER_TIME },
    });
  };

  return (
    <Flex align="center" pl={4} pr={2} justify="space-between" w="100%" h="52px">
      {showSearch || isDineIn ? (
        <MenuSearchInput
          autoFocus={true}
          onBlur={handleBlur}
          {...(isSmallerThanMed && { 'data-testid': 'search-input' })}
        />
      ) : (
        <Fragment>
          {logoUrl && (
            <Box w="80px" h="40px" pos="relative">
              <Image
                className="nav-bar-logo"
                src={logoUrl}
                fill
                objectFit="contain"
                alt={logoAlt}
              />
            </Box>
          )}

          <HStack spacing={1} fontSize="sm" color="blackAlpha.900">
            <Text>{orderTypeDisplayName}</Text>
            <Text>•</Text>
            <Button
              variant="ghost"
              px={0}
              colorScheme="blackAlpha"
              size="sm"
              fontWeight="normal"
              textDecor="underline"
              onClick={handleClick}
            >
              {estimatedTimeText}
            </Button>
          </HStack>
        </Fragment>
      )}
      {!isDineIn && (
        <IconButton
          icon={showSearch ? <Icon as={X} h={5} w={5} /> : <Search h={5} w={5} />}
          aria-label="Open search input"
          onClick={toggleSearch}
          variant="ghost"
          borderRadius="22px"
          size="sm"
        />
      )}
    </Flex>
  );
};
