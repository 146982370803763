import { useRef, useEffect, useCallback } from 'react';
import { useMenuDispatch, useMenuState } from '@olo-web/client-state';
import { useIsClient } from '@olo-web/utils/common/hooks/useIsClient';
import { useMenuFilteredBySearch } from '@olo-web/domain/menu/queries/useMenu';
import throttle from 'lodash/throttle';
import { useScreenSizeState } from '@olo-web/client-state';

type TProps = {
  footerInView: boolean;
};

export const useMenuGroupScrollListener = ({ footerInView }: TProps) => {
  const { selectedGroupId } = useMenuState();
  const menuDispatch = useMenuDispatch();
  const isClient = useIsClient();
  const { groups } = useMenuFilteredBySearch();

  const { isSmDown } = useScreenSizeState();

  const groupRefs = useRef({});
  const selectedGroupIdRef = useRef(selectedGroupId);

  const SCROLL_THRESHOLD = isSmDown ? 280 : 128;

  useEffect(() => {
    selectedGroupIdRef.current = selectedGroupId;
  }, [selectedGroupId]);

  const updateSelectedGroup = (groupId: string): void => {
    menuDispatch({ type: 'UPDATE_SELECTED_GROUP', payload: groupId });
  };

  const updateSelectedGroupThrottled = throttle(updateSelectedGroup, 400, {
    trailing: true,
  });

  const handleScroll = useCallback(
    (e) => {
      const SCROLL_TOP = e?.target?.scrollTop ?? 0;

      if (isClient && SCROLL_TOP < SCROLL_THRESHOLD) {
        const firstGroupId = groups?.[0]?.id;
        if (firstGroupId && firstGroupId !== selectedGroupId) {
          updateSelectedGroupThrottled(firstGroupId || null);
        }
        return;
      }

      for (const groupId in groupRefs.current) {
        if (!groupRefs?.current?.[groupId]) continue;

        const rect = groupRefs.current[groupId].getBoundingClientRect();

        const isAtTop =
          rect.top < SCROLL_THRESHOLD && rect.top - SCROLL_THRESHOLD > rect.height * -1;

        if (isAtTop && !footerInView) {
          const isSelected = selectedGroupIdRef.current === groupId;
          if (!isSelected) updateSelectedGroupThrottled(groupId);
          break;
        }
      }
    },
    [
      groups,
      selectedGroupId,
      SCROLL_THRESHOLD,
      updateSelectedGroupThrottled,
      footerInView,
      isClient,
    ]
  );

  const handleScrollThrottled = throttle(handleScroll, 200, {
    trailing: true,
  });

  return { groupRefs, handleScrollThrottled };
};
