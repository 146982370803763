import React, { useState, useEffect, useRef, useMemo } from 'react';

import {
  InputGroup,
  InputLeftElement,
  Input,
  InputRightElement,
  Spinner,
  Icon,
  InputProps,
  IconButton,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useKeyPress } from '@olo-web/utils/common/hooks';
import { X } from 'react-feather';
import { useMenuDispatch, useMenuState } from '@olo-web/client-state';
import { Search } from '@olo-web/assets/icons/Search.ui';
import debounce from 'lodash/debounce';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { useMenuFilteredBySearch } from '@olo-web/domain/menu/queries';

interface IMenuSearchInput extends InputProps {
  showClear?: boolean;
}

export function MenuSearchInput(props: IMenuSearchInput): JSX.Element {
  const { showClear, ...rest } = props;
  const { searchText } = useMenuState();
  const menuDispatch = useMenuDispatch();
  const escPressed = useKeyPress('Escape');
  const ref = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(searchText);
  const isDinein = useIsDineIn();
  const { groups } = useMenuFilteredBySearch();
  const itemCountRef = useRef<HTMLInputElement>(null);

  const setSearchText = useMemo(
    () =>
      debounce((value) => {
        menuDispatch({ type: 'UPDATE_SEARCH', payload: value });
      }, 500),
    [menuDispatch]
  );

  const itemCount = groups.reduce((accItemCount, menuGroup) => {
    return menuGroup.menuItems ? accItemCount + menuGroup.menuItems.length : accItemCount;
  }, 0);

  useEffect(() => {
    if (escPressed && ref.current !== null) ref.current.focus();
  }, [escPressed]);

  const handleSearchChange = (event?: any): void => {
    const t = event?.target?.value || '';
    setText(t);
    setSearchText(t);
    if (!t) ref.current?.focus();
  };

  const isSearching = (text || searchText) && text !== searchText;

  useEffect(() => {
    if (searchText && !isSearching) {
      setText(searchText);
      if (itemCountRef.current) itemCountRef.current.innerHTML = `${itemCount} items found`;
    }
  }, [searchText, isSearching, itemCount]);

  return (
    <>
      <InputGroup w="100%">
        <InputLeftElement h={6} w={4}>
          <Search h={4} w={4} />
        </InputLeftElement>
        <Input
          variant="flushed"
          _focus={{
            borderColor: 'black',
            borderBottomWidth: '2px',
          }}
          onChange={handleSearchChange}
          w="100%"
          size="xs"
          value={text}
          {...rest}
          placeholder="Search for food..."
          ref={ref}
          border={isDinein ? 'none' : ''}
          pl={7}
          _placeholder={{ color: 'blackAlpha.350' }}
          fontSize="16px"
        />
        <InputRightElement h={5}>
          {isSearching ? (
            <Spinner size="xs" color="blackAlpha.400" />
          ) : showClear && searchText ? (
            <IconButton
              aria-label="Clear search text"
              size="xs"
              variant="tertiary"
              colorScheme="blackAlpha"
              icon={<Icon as={X} h={5} w={5} />}
              onClick={handleSearchChange}
            />
          ) : null}
        </InputRightElement>
      </InputGroup>
      <VisuallyHidden aria-live="polite" ref={itemCountRef} />
    </>
  );
}
