import React from 'react';
import { StackDivider, VStack, Button, Icon, StackProps } from '@chakra-ui/react';

import { Plus } from 'react-feather';

type T = {
  onAddItemsClick: () => void;
  containerProps?: StackProps;
};

export function AddMoreItemsButton({ onAddItemsClick, containerProps }: T): JSX.Element {
  return (
    <VStack
      w="100%"
      divider={<StackDivider borderColor="gray.200" />}
      paddingBottom={2.5}
      {...containerProps}
    >
      <Button
        variant="tertiary"
        rightIcon={<Icon as={Plus} h={6} w={6} strokeWidth={2} />}
        colorScheme="primary"
        alignItems="center"
        justifyContent="space-between"
        p={0}
        onClick={onAddItemsClick}
        w="100%"
      >
        Add more items
      </Button>
    </VStack>
  );
}
