import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

interface IProps extends BoxProps {
  boxShadow?: string | Record<string, string>;
}
export function Card(props: IProps): JSX.Element {
  const { children, boxShadow = 'md', ...rest } = props;
  return (
    <Box
      borderRadius={4}
      bg="white"
      p={4}
      boxShadow={boxShadow}
      transition="0.2s"
      _hover={{
        boxShadow: '0 0 10px gray',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
