import React from 'react';
import { Flex, Text, Button } from '@chakra-ui/react';
import { useBagBar } from '../hooks';
import { BagBody } from '../BagBody';

export const BagSideBar = () => {
  const {
    order,
    orderLoading,
    goToCheckout,
    productCount,
    routerLoading,
    isValidatingOrder,
    bagTitle,
    bagButtonText,
  } = useBagBar();

  return (
    <Flex
      w="100%"
      flexDir="column"
      align="flex-start"
      justify="space-between"
      h="100%"
      borderLeft="1px solid"
      borderColor="blackAlpha.100"
    >
      <Text as="h2" textStyle="h3" mt={4} ml={4} mb={0}>
        {bagTitle}
      </Text>
      <Flex
        flexDir="column"
        w="100%"
        flex={1}
        overflowY="scroll"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
            width: '0px',
          },
        }}
      >
        <BagBody order={order} isLoading={orderLoading} />
      </Flex>

      {!!productCount && (
        <Flex align="center" mt="auto" p={4} w="100%">
          <Button
            w="100%"
            colorScheme="primary"
            size="lg"
            onClick={goToCheckout}
            data-testid="your-bag-button"
            isLoading={isValidatingOrder || routerLoading}
          >
            {bagButtonText}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
