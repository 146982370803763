import { Box, Flex, Text } from '@chakra-ui/react';
import React, { Ref } from 'react';
import { BagIcon } from '@olo-web/assets/icons/BagIcon.ui';

interface IBagIconForButtonProps {
  totalQuantity: number;
  productCount: number;
  innerRef?: Ref<HTMLDivElement>;
  open: boolean;
  buttonConfig: any;
  small?: boolean;
}

export function BagIconForButton({
  totalQuantity,
  productCount,
  innerRef,
  open,
  buttonConfig,
  small,
}: IBagIconForButtonProps): JSX.Element {
  return (
    <Box pos="relative">
      <BagIcon {...buttonConfig} color={open ? 'primary.350' : 'white'} mr={3} />
      <Flex
        pos="absolute"
        ref={innerRef}
        top={small ? 2 : 4}
        left={small ? 2.5 : 3}
        bg={open ? 'primary.350' : 'white'}
        h={small ? 3.5 : 4}
        w={small ? 3.5 : 4}
        borderRadius="8px"
        align="center"
        justify="center"
        visibility={productCount > 0 ? 'visible' : 'hidden'}
      >
        <Text
          color={open ? 'white' : 'primary.350'}
          fontSize={`${totalQuantity < 10 ? '0.75' : '0.65'}rem`}
          fontWeight="bold"
          mt="0.5px"
          lineHeight={small ? 0.5 : 1}
        >
          {totalQuantity}
        </Text>
      </Flex>
    </Box>
  );
}
