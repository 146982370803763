import React, { memo, FC } from 'react';

import { ProductOrderedInfo } from '@molecules/ProductOrderedInfo';
import { CustomerMessageBanner } from '@olo-web/components/molecules/CustomerMessageBanner';
import { DeliveryMinimumAmountAlert } from '@olo-web/components/molecules/DeliveryMinimumAmountAlert';
import { OverMaxOrderAmountInlineAlert } from '@olo-web/components/molecules/OverMaxOrderAmountInlineAlert';
import { ProductUnavailability } from '@olo-web/components/molecules/ProductOrderedInfo/ProductUnavailability';
import { VStack } from '@chakra-ui/react';

type T = {
  isUnsentItemsOnly?: boolean;
  guestsWithFilteredItemsToDisplay?: IGuest[];
  itemsToDisplay?: IOrderItem[];
};

const ProductDetailsComponent: FC<T> = ({
  isUnsentItemsOnly,
  guestsWithFilteredItemsToDisplay,
  itemsToDisplay,
}) => {
  return (
    <VStack spacing={{ base: 2, md: 4 }} w="100%">
      <OverMaxOrderAmountInlineAlert />
      <DeliveryMinimumAmountAlert />
      <CustomerMessageBanner />
      <ProductUnavailability />
      <ProductOrderedInfo
        showActionButtons
        isUnsentItemsOnly={isUnsentItemsOnly}
        itemsToDisplay={itemsToDisplay}
        guestsWithFilteredItems={guestsWithFilteredItemsToDisplay}
      />
    </VStack>
  );
};

export const ProductDetails = memo(ProductDetailsComponent);
