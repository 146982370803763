import { Flex, FlexProps } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

interface IMenuHeaderLogo extends FlexProps {
  src?: string;
  alt?: string;
}

export const MenuHeaderLogo = (props: IMenuHeaderLogo) => {
  const { src, alt, ...rest } = props;

  if (!src) return null;

  return (
    <Flex justify="center" align="center" bg="white" p={2} borderRadius="md" {...rest} zIndex="2">
      <Image
        className="main-logo"
        src={src}
        width={200}
        height={96}
        objectFit="contain"
        alt={`${alt} logo image`}
        data-testid="location-logo-image"
      />
    </Flex>
  );
};
