import { ICompareResult } from './convertToColorAndAnimationArray';

interface IDigitResult extends ICompareResult {
  digit: string;
}

export const convertToDigitsArray = (
  valueToFixed: string,
  compareArray: ICompareResult[]
): IDigitResult[] => {
  if (!compareArray?.length) return [];
  const arr = compareArray.slice();
  const digitsArray = valueToFixed.split('').map((digit) => {
    if (digit === '.') {
      return {
        color: arr[0]?.color,
        bool: null,
        digit,
      };
    }
    const anim = arr.shift();
    return { ...anim, digit };
  });
  return digitsArray;
};
