import React, { ForwardRefRenderFunction, useState, useCallback, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import { useRouter } from 'next/router';
import {
  useEstimatedTimeText,
  useIsDelivery,
  useIsThirdPartyDelivery,
  useTableNumFromQuery,
} from '@olo-web/utils/common/hooks';
import { useOrderTipsDispatch, useSavedDineInContextDispatch } from '@olo-web/client-state';
import {
  useModalDispatch,
  useDeliveryAddressState,
  useModalState,
  useSavedDineInContextState,
} from '@olo-web/client-state';
import { EOrderStatus, EOrderTypes } from '@olo-web/types/enums';
import {
  Button,
  Divider,
  Flex,
  forwardRef,
  Icon,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Clock, Truck } from 'react-feather';
import { PillTabs } from '@olo-web/components/atoms/PillTabs';
import { PurchaseGiftCardsButton } from '@olo-web/components/atoms/PurchaseGiftCardsButton';
import { useMerchantOrderTypeDetails, useMerchant } from '@domain/merchants/queries';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useOrderTypeItems } from '@olo-web/domain/orders/hooks/useOrderTypeItems';
import { useUpdateOrderFulfillment } from '@olo-web/domain/orders/hooks/useUpdateOrderFulfillment';
import { getDefaultTipsValue } from '@olo-web/domain/orders/functions/getDefaultTipsValue';
import { EModalTypes, EModalSubTypes } from '@olo-web/types/enums';
import { useOrderType, useDeliveryModal } from '@olo-web/utils/common/hooks';
import { useMemo } from 'react';
import { useEstimateDelivery } from '@olo-web/domain/deliveryService/queries/useEstimateDelivery';
import { MenuHeaderLocationButton } from '../MenuHeaderButtons/MenuHeaderLocationButton';
import { useIsOrderTypeSelectorHidden } from '@olo-web/theme/hooks';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';
import { OrderTypeSelectorHiddenReplacementBanner } from '@olo-web/components/atoms/OrderTypeSelectorHiddenReplacementBanner';
import { JoinedTableHeader } from './JoinedTableHeader';
import { useOrderIdFromQuery } from '@olo-web/utils/common/hooks/useOrderIdFromQuery';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';
import { useUpdateOrderInCache } from '@domain/orders/hooks';
import { useGoToConfirmation } from '@olo-web/utils/common/hooks/useGoToConfirmation';

const MenuHeaderDetailsRenderer: ForwardRefRenderFunction<HTMLButtonElement> = (_, ref) => {
  // The following two states have been created to prevent Next.js/React hydration rendering errors which force the loading of the entire page from the client-side instead of from the server
  const [buttonText, setButtonText] = useState('Start order');
  const [locationAddress, setlocationAddress] = useState<IDeliveryAddress>(null);

  const orderType = useOrderType();
  const { data: merchant } = useMerchant();
  const { data: order } = useOrder();
  const { updateOrderFulfillment, isLoading } = useUpdateOrderFulfillment();
  const orderTypeItems = useOrderTypeItems();
  const modalDispatch = useModalDispatch();
  const { modalKey } = useModalState();
  const { address } = useDeliveryAddressState();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { data: deliveryEstimate, isLoading: isDeliveryEstimateLoading } = useEstimateDelivery();
  const { data: orderDetails } = useMerchantOrderTypeDetails();
  const orderTipsDispatch = useOrderTipsDispatch();
  const goToConfirmation = useGoToConfirmation();

  const isDineIn = useIsDineIn();
  const router = useRouter();
  const estimatedTimeText = useEstimatedTimeText(
    isThirdPartyDelivery ? deliveryEstimate?.deliveryTime : order?.orderDateTime
  );
  const hasDeliveryAddress = locationAddress?.address1;
  const { openDeliveryModal } = useDeliveryModal();
  const isDelivery = useIsDelivery();
  const { merchantId } = router.query;
  const dineInState = useSavedDineInContextState();
  const addressNeeded = useMemo(
    () => isDelivery && !locationAddress,
    [locationAddress, isDelivery]
  );
  const isOrderTypeSelectionHidden = useIsOrderTypeSelectorHidden();
  const handleDateTimeClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: { modalKey: EModalTypes.ORDER_TIME },
    });
  };
  const handleOrderTypeChange = (orderType) => {
    const deliverySelected =
      orderType === EOrderTypes.DELIVERY || orderType === EOrderTypes.DOORDASH_DRIVE;
    if (!hasDeliveryAddress && deliverySelected) {
      openDeliveryModal();
    } else if ((hasDeliveryAddress && deliverySelected) || orderType === EOrderTypes.PICKUP) {
      updateOrderFulfillment(orderType);
    }
  };

  // This effect is updating the tip value to 20% when the order type is DDD, this is why this effect only needs to run when `isThirdPartyDelivery` changes.
  useUpdateEffect(() => {
    orderTipsDispatch({
      type: 'TIPS_VALUE_CHANGE',
      payload: {
        merchantId: merchantId as string,
        value: String(getDefaultTipsValue(orderDetails, isThirdPartyDelivery)),
      },
    });
  }, [isThirdPartyDelivery]);

  const pillTabItems = useMemo(() => {
    return orderTypeItems.map((i) => ({
      label: i.available ? (
        i.label
      ) : (
        <Text fontSize="xs" color="blackAlpha.350" textAlign="center" lineHeight="short">
          {i.label}
          <br /> unavailable
        </Text>
      ),
      value: i.value,
      disabled: !i.available,
    }));
  }, [orderTypeItems]);

  const handleAddressClick = () => {
    if (orderType === EOrderTypes.PICKUP) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.MERCHANT_LOCATION,
          subType: EModalSubTypes.LOCATION.MAP,
        },
      });
    } else {
      openDeliveryModal();
    }
  };

  const openInitDineInOrderModal = useCallback(() => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.INIT_DINEIN_ORDER,
        modalContext: {
          onClose: () => {
            if (router.query.orderId) {
              delete router.query.orderId;
              router.replace(
                {
                  pathname: router.pathname,
                  query: router.query,
                },
                undefined,
                { shallow: true }
              );
            }
          },
        },
      },
    });
  }, [modalDispatch, router]);

  const tableNum = useTableNumFromQuery();
  const orderIdFromQuery = useOrderIdFromQuery();
  const updateOrderInCache = useUpdateOrderInCache();
  const savedDineInContextDispatch = useSavedDineInContextDispatch();
  const orderIdFeatureFlagEnabled = useOrderIdFeatureFlagEnabled();
  const { guestId: guestIdFromQuery } = router.query;
  const isQueryGuestOnOrder = order?.guests?.find((guest) => guest?.id === guestIdFromQuery);
  const orderIdAndGuestIdExist = orderIdFromQuery && guestIdFromQuery;

  useEffect(() => {
    if (
      (tableNum || (orderIdFeatureFlagEnabled && orderIdFromQuery)) &&
      !dineInState &&
      !orderIdAndGuestIdExist
    ) {
      openInitDineInOrderModal();
    }
  }, [
    dineInState,
    openInitDineInOrderModal,
    orderIdAndGuestIdExist,
    orderIdFeatureFlagEnabled,
    orderIdFromQuery,
    tableNum,
  ]);

  useEffect(() => {
    if (order && orderIdAndGuestIdExist && !isQueryGuestOnOrder) {
      openInitDineInOrderModal();
    }

    if (order && orderIdAndGuestIdExist && isQueryGuestOnOrder && !dineInState) {
      updateOrderInCache(order, ['order', merchantId as string, order.id]);
      savedDineInContextDispatch({
        type: 'SAVE',
        payload: {
          guest: isQueryGuestOnOrder,
          orderId: order.id,
        },
      });
    }
    orderIdFeatureFlagEnabled ? setButtonText('Start order') : setButtonText('Start or join order');
    setlocationAddress(address);
  }, [
    guestIdFromQuery,
    isQueryGuestOnOrder,
    openInitDineInOrderModal,
    order,
    orderIdAndGuestIdExist,
    orderIdFeatureFlagEnabled,
    address,
    dineInState,
    merchantId,
    savedDineInContextDispatch,
    updateOrderInCache,
  ]);

  const pillTabValue = useMemo(() => {
    const addressModalOpen =
      modalKey === EModalTypes.CUSTOMER_DELIVERY_ADDRESS ||
      modalKey === EModalTypes.CUSTOMER_DELIVERY_SAVED_ADDRESSES;
    if (addressModalOpen && merchant?.orderTypeIds[EOrderTypes.DOORDASH_DRIVE])
      return EOrderTypes.DOORDASH_DRIVE;
    if (addressModalOpen && merchant?.orderTypeIds[EOrderTypes.DELIVERY])
      return EOrderTypes.DELIVERY;
    return orderType;
  }, [orderType, modalKey, merchant?.orderTypeIds]);

  useEffect(() => {
    const isDineInOrderCompleted =
      order?.groupOrderInfo?.status === EOrderStatus.COMPLETED ||
      (order?.balanceDueAmount === '0' && order?.closedDateTime);
    if (isDineIn && isDineInOrderCompleted) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.CLOSED_ORDER,
          modalContext: {
            onClose: () => goToConfirmation(),
            drawerHeaderProps: { hideArrow: true },
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order?.groupOrderInfo?.status, order?.balanceDueAmount, order?.closedDateTime]);

  if (isDineIn) {
    if (!dineInState) {
      return (
        <Button
          data-testid="start-order-button"
          fontSize="19px"
          fontWeight="600"
          lineHeight={6}
          bg="primary.300"
          boxSizing="border-box"
          mt={{ md: '25px' }}
          w={{ base: '100%', md: '300px' }}
          ref={ref}
          borderRadius={4}
          _hover={{
            backgroundColor: 'primary.400',
          }}
          _active={{
            backgroundColor: 'primary.500',
          }}
          onClick={openInitDineInOrderModal}
        >
          {buttonText}
        </Button>
      );
    } else {
      return <JoinedTableHeader ref={ref} />;
    }
  }
  return (
    <VStack
      w="100%"
      mt={{ base: 0, md: isOrderTypeSelectionHidden ? 0 : 4 }}
      spacing={4}
      alignItems={{ base: 'center', md: 'flex-start' }}
      marginBottom={3}
    >
      {isOrderTypeSelectionHidden && (
        <OrderTypeSelectorHiddenReplacementBanner
          borderTopRadius="lg"
          display={{ base: 'flex', md: 'none' }}
        />
      )}
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        py={{ base: isOrderTypeSelectionHidden ? 0 : 4, md: 0 }}
        px={{ base: 4, md: 0 }}
        pb={0}
        w="100%"
      >
        {orderTypeItems.length !== 0 && !isOrderTypeSelectionHidden && (
          <PillTabs
            items={pillTabItems}
            value={pillTabValue}
            onChange={handleOrderTypeChange}
            width={{ base: '100%', md: '304px' }}
            isDisabled={!order}
            isLoading={isLoading}
          />
        )}
        <Button
          variant="solid"
          bg="blackAlpha.100"
          _hover={
            !merchant?.asapOnly && {
              bg: 'blackAlpha.200',
            }
          }
          _active={
            !merchant?.asapOnly && {
              bg: 'blackAlpha.300',
            }
          }
          rightIcon={<Icon as={addressNeeded ? Truck : Clock} h={5} w={5} />}
          color="blackAlpha.900"
          borderRadius="22px"
          height="44px"
          pr={4}
          pl={6}
          loadingText="Updating..."
          isLoading={isDeliveryEstimateLoading}
          textDecor={!merchant?.asapOnly && 'underline'}
          isDisabled={!order}
          onClick={addressNeeded ? handleAddressClick : handleDateTimeClick}
          data-testid="lead-time"
          aria-label={`${
            orderType === EOrderTypes.PICKUP ? 'PickUp' : 'Delivery'
          } time is ${estimatedTimeText}`}
        >
          {addressNeeded ? 'Enter address' : estimatedTimeText}
        </Button>
      </Stack>
      <span style={{ position: 'absolute' }} ref={ref as any} />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
        justify={{ base: 'center', md: 'space-between' }}
        w="100%"
      >
        <Flex align="center" px={{ base: 4, md: 0 }} justify="flex-start" wrap="wrap">
          {!merchant ? (
            <Skeleton w="600px" h="30px" />
          ) : isDelivery && !hasDeliveryAddress ? null : (
            <MenuHeaderLocationButton />
          )}
        </Flex>
        <Divider display={{ md: 'none' }} borderColor="gray.200" />
        <PurchaseGiftCardsButton mt={3} mr={{ base: 0, md: -4 }} />
      </Flex>
    </VStack>
  );
};

export const MenuHeaderDetails = forwardRef(MenuHeaderDetailsRenderer);
