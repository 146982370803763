import React, { FC } from 'react';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { VStack, StackDivider } from '@chakra-ui/react';
import { MenuGroupRibbon, useSensitiveScrollNavBar } from '../..';
import { NavBarDetailsDesktop, NavBarDetailsMobile } from './NavBarDetails';
import { useIsDineIn } from '@olo-web/utils/common/hooks/useIsDineIn';

export type TScrollSensitiveNavBar = {
  show: boolean;
  showClear?: boolean;
};

export const ScrollSensitiveNavBar: FC<TScrollSensitiveNavBar> = ({ show }) => {
  const { isMd, order, styles, showNavBar, variants } = useSensitiveScrollNavBar({ show });
  const isDineIn = useIsDineIn();

  if (!order && !isDineIn) return null;

  return (
    <MotionBox
      {...styles}
      display={show ? 'inline' : 'none'}
      variants={variants}
      initial="hidden"
      animate={showNavBar ? 'visible' : 'hidden'}
    >
      <VStack
        divider={<StackDivider />}
        spacing={0}
        w="100%"
        borderBottom={isMd ? 'none' : '1px solid'}
        borderBottomColor="blackAlpha.100"
      >
        {isMd ? <NavBarDetailsDesktop show={show} /> : <NavBarDetailsMobile show={show} />}
        {!isMd && <MenuGroupRibbon />}
      </VStack>
    </MotionBox>
  );
};
