import React, { FC, memo } from 'react';
import {
  VStack,
  StackDivider,
  Button,
  Icon,
  Progress,
  Text,
  Spinner,
  Divider,
} from '@chakra-ui/react';
import { EmptyBag } from '@olo-web/components/atoms/EmptyBag';
import { BagWithSentItems } from '@olo-web/components/atoms/BagWithSentItems';
import { ProductDetails } from '@atoms/ProductDetails';
import { ProductTotals } from '@olo-web/components/molecules/ProductTotals';
import { ChevronRight } from 'react-feather';
import { ApplyOffersButton } from '../../Checkout/ApplyOffersButton';
import { AnimatePresence } from 'framer-motion';
import { AddMoreItemsButton } from '@olo-web/components/atoms/AddMoreItemsButton';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes, EOrderStatus } from '@olo-web/types/enums';
import { useIsDineIn } from '@olo-web/utils/common/hooks';
import { useMerchant } from '@domain/merchants/queries/useMerchant';
import BagBodyBanner from './BagBodyBanner.ui';
import { useHasUnsentItems } from '../hooks/useHasUnsentItems';
import { ProductUnavailability } from '@olo-web/components/molecules/ProductOrderedInfo/ProductUnavailability';
import { useItemFiltering } from '@olo-web/utils/common/hooks/useItemFiltering';
import { useBagBar } from '@olo-web/components/templates/Bag/hooks/useBagBar';

type T = {
  order: IOrder;
  isLoading?: boolean;
  onAddItemsClick?: () => void;
  showSendItemsButton?: boolean;
  buttonProps?: any;
  isSendingItems?: boolean;
  handleOpenBagDrawer?: () => void;
};

const animate = { opacity: [0, 1], transition: { duration: 0.6 } };
const BagBodyComponent: FC<T> = ({
  order,
  isLoading,
  onAddItemsClick,
  showSendItemsButton,
  buttonProps,
  isSendingItems,
  handleOpenBagDrawer,
}) => {
  const isEmpty = !!(!order?.items || !order?.items.length);
  const modalDispatch = useModalDispatch();
  const isDineIn = useIsDineIn();
  const { data: merchant } = useMerchant();
  const hasUnsentItems = useHasUnsentItems();
  const hasSentItems = !!order?.items?.find((item) => item?.sentDateTime !== null);
  const allItemsArePreSent = order?.isReserveOrder && order?.items?.every((item) => !item?.pending);
  const someItemsArePreSent = order?.isReserveOrder && order?.items?.some((item) => !item?.pending);
  const showReserveSpecificStuff =
    order?.isReserveOrder && order?.groupOrderInfo?.status === EOrderStatus.PENDING;
  const guestInfo = order?.guests?.find(
    (guest) => guest?.id === order?.groupOrderInfo?.statusGuestId
  );
  const allItemsSent = isDineIn && merchant?.dineinPayLaterEnabled && !hasUnsentItems;
  const sendItemsClick = () => {
    if (!isSendingItems) {
      buttonProps.goToCheckout();
    }
  };
  const areAllItemsSubmitted = showReserveSpecificStuff ? allItemsArePreSent : allItemsSent;
  const { submittedItems, unsubmittedItems, guestsWithSubmittedItems, guestsWithUnsubmittedItems } =
    useItemFiltering();

  const handleClick = (): void => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.ORDERED_ITEMS,
        modalContext: {
          isSentItemsOnly: !allItemsArePreSent,
          isUnsentItemsOnly: hasSentItems,
          itemsToDisplay: submittedItems,
          guestsWithFilteredItemsToDisplay: guestsWithSubmittedItems,
        },
      },
    });
  };
  const { bagButtonText } = useBagBar();

  return (
    <AnimatePresence>
      {isLoading || !order ? (
        <MotionBox animate={animate} key="loading" w="100%" h="100%">
          <VStack
            h="100%"
            alignItems="center"
            justifyContent="center"
            spacing={4}
            data-testid="your-bag-is-loading"
          >
            <Text>Your bag is loading...</Text>
            <Progress w="90%" borderRadius={10} isIndeterminate colorScheme="primary" />
          </VStack>
        </MotionBox>
      ) : isEmpty ? (
        <MotionBox animate={animate} key="empty">
          <EmptyBag />
        </MotionBox>
      ) : isSendingItems ? (
        <VStack bg="white">
          <MotionBox animate={animate} key="empty">
            <VStack
              h={200}
              alignItems="center"
              justifyContent="center"
              spacing={4}
              data-testid="your-items-are-sending"
            >
              <Spinner color="primary.500" thickness="4px" speed="0.65s" size="xl" />
            </VStack>
          </MotionBox>
          <VStack w="100%" display={{ base: 'flex', md: 'none' }}>
            <Divider borderColor="gray.200" width="92%" />
            <AddMoreItemsButton
              onAddItemsClick={onAddItemsClick}
              containerProps={{ pl: 4, pr: 4 }}
            />
          </VStack>
        </VStack>
      ) : (
        <MotionBox animate={animate} key="bag">
          <VStack
            p={{ base: 0, md: 2, lg: 4 }}
            pb={{ base: 4, md: 2, lg: 4 }}
            spacing={{ base: 6, md: 4 }}
            w="100%"
          >
            <VStack
              w="100%"
              p={{ base: 4, md: 0 }}
              pt={{ base: 0, md: 1 }}
              pb={0}
              bg="white"
              boxShadow={{
                base: '0px 2px 2px rgba(0,0,0,0.05)',
                md: 'none',
              }}
              spacing={4}
            >
              <>
                <BagBodyBanner
                  isDineIn={isDineIn}
                  hasSentItems={hasSentItems}
                  dineinPayLaterEnabled={merchant?.dineinPayLaterEnabled}
                  statusOrder={order?.groupOrderInfo?.status}
                  guestName={guestInfo?.name}
                  isReserveOrder={showReserveSpecificStuff}
                  allItemsPreSent={allItemsArePreSent}
                  preAuthRequired={merchant?.dineinpreauthrequired}
                />
                {areAllItemsSubmitted ? (
                  <>
                    <ProductUnavailability />
                    <BagWithSentItems isReserveOrder={showReserveSpecificStuff} />
                  </>
                ) : (
                  <>
                    <VStack w="100%" spacing={4}>
                      <ProductDetails
                        isUnsentItemsOnly={hasSentItems}
                        itemsToDisplay={unsubmittedItems}
                        guestsWithFilteredItemsToDisplay={guestsWithUnsubmittedItems}
                      />
                    </VStack>
                    {showSendItemsButton && (
                      <Button
                        w="100%"
                        colorScheme="primary"
                        size="lg"
                        onClick={sendItemsClick}
                        isLoading={buttonProps.isValidatingOrder || buttonProps.routerLoading}
                        data-testid="send-items-button"
                        mb={4}
                      >
                        {bagButtonText}
                      </Button>
                    )}
                  </>
                )}
              </>
              <AddMoreItemsButton
                onAddItemsClick={onAddItemsClick}
                containerProps={{ display: { base: 'block', md: 'none' } }}
              />
            </VStack>
            {isDineIn &&
              (hasSentItems || someItemsArePreSent) &&
              merchant?.dineinPayLaterEnabled && (
                <VStack
                  w="100%"
                  p={{ base: 3, md: 0 }}
                  pt={{ base: 3, md: 0 }}
                  bg="white"
                  boxShadow={{
                    base: '0px 2px 2px rgba(0,0,0,0.05)',
                    md: 'none',
                  }}
                  divider={<StackDivider />}
                  spacing={4}
                >
                  <VStack w="100%" divider={<StackDivider borderColor="gray.200" />}>
                    <Button
                      data-testid="view-ordered-items-button"
                      variant="tertiary"
                      w="100%"
                      rightIcon={<Icon as={ChevronRight} h={6} w={6} strokeWidth={2} />}
                      colorScheme="black"
                      alignItems="center"
                      justifyContent="space-between"
                      p={0}
                      onClick={handleClick}
                    >
                      View ordered items
                    </Button>
                  </VStack>
                </VStack>
              )}

            {(!order?.guests || order?.guests?.length === 1) && !isDineIn && (
              <VStack
                p={{ base: 4, md: 0 }}
                bg="white"
                boxShadow={{
                  base: '0px 2px 2px rgba(0,0,0,0.05)',
                  md: 'none',
                }}
                mb={{ base: 4, md: 0 }}
                w="100%"
                spacing={4}
              >
                <ProductTotals />
                <ApplyOffersButton handleOpenBagDrawer={handleOpenBagDrawer} />
              </VStack>
            )}
          </VStack>
        </MotionBox>
      )}
    </AnimatePresence>
  );
};

export const BagBody = memo(BagBodyComponent);
