import React, { useMemo } from 'react';
import { Banner } from '@olo-web/components/atoms/Banner';
import { useOrderUnavailabilityDispatch } from '@olo-web/client-state';
import { useHasUnsentItems } from '@olo-web/components/templates/Bag/hooks';
import { useMerchant } from '@olo-web/domain';
import { useIsDineIn } from '@olo-web/utils/common/hooks';
type T = {
  unavailableItems: string[];
  reducedQuantity?: boolean;
  subItems?: string[];
};

export const useProductUnavailabilityText = ({
  unavailableItems,
  reducedQuantity,
  subItems = [],
}: T) => {
  const [title, description] = useMemo(() => {
    const isOne = unavailableItems?.length === 1 || subItems?.length === 1;

    const pluralSignularText1 = isOne ? 'an item' : `${unavailableItems?.length} items`;
    const pluralSignularText2 = isOne ? "it's" : "they're";

    const t = reducedQuantity
      ? `Sorry, we reduced the quantity of ${pluralSignularText1} in your bag to match what we have in stock:`
      : `Sorry, we removed ${pluralSignularText1} from your bag because ${pluralSignularText2} out of stock:`;

    const d = [...unavailableItems, ...subItems];

    return [t, d];
  }, [unavailableItems, reducedQuantity, subItems]);

  return [title, description];
};

export const ProductOrderedUnavailability = ({ unavailableItems, reducedQuantity }: T) => {
  const unavailablilityDispatch = useOrderUnavailabilityDispatch();
  const isDineIn = useIsDineIn();
  const { data: merchant } = useMerchant();
  const hasUnsentItems = useHasUnsentItems();
  const allItemsSent = isDineIn && merchant?.dineinPayLaterEnabled && !hasUnsentItems;

  const [title, description] = useProductUnavailabilityText({ unavailableItems, reducedQuantity });

  const handleClose = () => {
    unavailablilityDispatch({ type: 'REMOVE_ITEMS', payload: unavailableItems });
  };

  if (!unavailableItems?.length) return null;
  if (!allItemsSent)
    return <Banner status="error" title={title} description={description} onClose={handleClose} />;
  else return;
};
