import { useScreenSizeState } from '@olo-web/client-state';
import { useMerchant, useMerchantGroup } from '@domain/merchants/queries';
import { useMerchantImages } from '@domain/merchants/hooks/useMerchantImages';
import { useInView } from 'react-intersection-observer';

export const useMenuHeader = () => {
  const { data: merchant, isLoading: merchantLoading } = useMerchant();
  const { data: group, isLoading: groupLoading } = useMerchantGroup();
  const { backgroundUrl } = useMerchantImages();
  const { isMdDown } = useScreenSizeState();
  const { inView, ref } = useInView();

  const T = isMdDown ? 0.6 : 0.5;
  const B = isMdDown ? 0.4 : 0.15;

  const bg = backgroundUrl
    ? {
        bg: `linear-gradient( rgba(0, 0, 0, ${T}), rgba(0, 0, 0, ${B}) )`,
        bgPos: 'center',
        bgSize: 'cover',
      }
    : {
        bgGradient: 'linear(to-b, primary.600, primary.400)',
      };

  return {
    group,
    groupLoading,
    inView,
    merchant,
    merchantLoading,
    ref,
    bg,
    backgroundUrl,
  };
};
