import React, { useMemo } from 'react';
import { useOrderUnavailabilityState } from '@olo-web/client-state';
import { GridItem, VStack } from '@chakra-ui/react';
import { ProductOrderedUnavailability } from '../common/ProductOrderedUnavailability.ui';
import { ProductOrderedSubItemUnavailability } from '../common/ProductOrderedSubItemUnavailability.ui';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useProductUnavailabilityText } from '@olo-web/components/molecules/ProductOrderedInfo/common/ProductOrderedUnavailability.ui';
import { useHandleAsapLogicOnServer, useToast } from '@olo-web/utils/common/hooks';

export const ProductUnavailability = () => {
  const { unavailableItems, unavailableModifiers } = useOrderUnavailabilityState();
  const isHandleAsapLogicOnServer = useHandleAsapLogicOnServer();
  const { data: order } = useOrder();
  const { notify, toast } = useToast();
  const [outOfStock, reducedQuantity, subItemsOutOfStock] = useMemo(() => {
    const unavailItems = unavailableModifiers
      ? [...(unavailableItems ?? []), ...unavailableModifiers]
      : [...(unavailableItems ?? [])];
    const outOfStock: (IUnavailableItems | IUnavailableModifiers)[] = [];
    const reducedQuantity: (IUnavailableItems | IUnavailableModifiers)[] = [];
    const subItemsOutOfStock: (IUnavailableItems | IUnavailableModifiers)[] = [];
    for (const unavailableItem of unavailItems) {
      const isoutOfStock = +(unavailableItem as IUnavailableItems)?.quantityInStock < 1;
      const isSubItem = !!(unavailableItem as IUnavailableItems)?.parentItemId;
      if ((unavailableItem as IUnavailableModifiers)?.modifierInfoId) {
        subItemsOutOfStock.push({
          ...unavailableItem,
          parentItemID: (unavailableItem as IUnavailableModifiers)?.parentItemId,
        });
        continue;
      }
      if (isSubItem) {
        // isoutOfStock is ignored here because the item is removed from the returned order form the
        // ...preflight endpoint, otherwise a new experience could be created (like an action
        // ...button with previous selections)
        subItemsOutOfStock.push(unavailableItem);
        continue;
      }
      if (isoutOfStock) {
        outOfStock.push(unavailableItem);
        continue;
      }
      reducedQuantity.push(unavailableItem);
    }
    return [outOfStock, reducedQuantity, subItemsOutOfStock];
  }, [unavailableItems, unavailableModifiers]);

  const items = outOfStock?.map((u) => u?.['orderItems'][0]?.name);
  const subItems = subItemsOutOfStock?.map((unavailSub) => {
    return unavailSub?.['name'] || unavailSub?.['orderItems'][0].name;
  });

  const unavailToastId = 'unavailable-items-toast';
  const unavailSubToastId = 'unavailable-sub-items-toast';
  const [title, description] = useProductUnavailabilityText({
    unavailableItems: items,
    reducedQuantity: !!reducedQuantity.length,
    subItems: subItems,
  });
  if (!unavailableItems?.length && !unavailableModifiers?.length) return null;

  if (order?.items.length === 0 && !isHandleAsapLogicOnServer) {
    if ((items.length || reducedQuantity.length) && !toast.isActive(unavailToastId)) {
      notify({
        status: 'error',
        variant: 'left-accent',
        title: title,
        duration: 5000,
        description: description,
        id: unavailToastId,
      });
    }
    if (subItemsOutOfStock.length && !toast.isActive(unavailSubToastId)) {
      notify({
        status: 'error',
        variant: 'left-accent',
        title: title,
        duration: 5000,
        description: description,
        id: unavailSubToastId,
      });
    }
  }
  return (
    <>
      <GridItem colSpan={1} />
      <GridItem colSpan={7}>
        <VStack>
          {!!items?.length && (
            <ProductOrderedUnavailability unavailableItems={items} reducedQuantity={false} />
          )}
          {reducedQuantity?.length && (
            <ProductOrderedUnavailability
              unavailableItems={reducedQuantity.map((u) => u?.['orderItems'][0]?.name)}
              reducedQuantity
            />
          )}
          {subItemsOutOfStock?.length &&
            subItemsOutOfStock.map((item: IUnavailableItems) => (
              <ProductOrderedSubItemUnavailability
                key={item['parentItemID']}
                unavailableItem={item}
              />
            ))}
        </VStack>
      </GridItem>
    </>
  );
};
