import React, { useMemo, FC, useState } from 'react';
import { Banner } from '@olo-web/components/atoms/Banner';
import { useMount } from 'react-use';
import { useModalDispatch, useOrderUnavailabilityDispatch } from '@olo-web/client-state';
import { useItem } from '@olo-web/domain/menu/queries';
import { EModalTypes } from '@olo-web/types/enums';

type T = {
  unavailableItem: IUnavailableItems;
};

export const ProductOrderedSubItemUnavailability: FC<T> = ({ unavailableItem }) => {
  const { data: item, refetch } = useItem(unavailableItem.parentItemId);
  const unavailablilityDispatch = useOrderUnavailabilityDispatch();
  const modalDispatch = useModalDispatch();
  const [hasClicked, setHasClicked] = useState(false);

  const handleClose = () => {
    setHasClicked(true);
    unavailablilityDispatch({
      type: 'REMOVE_ITEMS',
      payload: [unavailableItem.menuItemLocationId],
    });
    unavailablilityDispatch({ type: 'CLEAR_MODS' });
  };

  const handleMakeNewSelection = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.ITEM_DETAILS,
        modalContext: {
          item: {
            menuItemLocationId: unavailableItem.parentItemId,
          },
        },
      },
    });
    handleClose();
  };

  const [title, description] = useMemo(() => {
    if (!item) return ['', ''];
    const title = `Sorry, we removed an item from your bag because one or more of your selections is out of stock:`;
    const description = item?.name;
    return [title, description];
  }, [item]);

  useMount(() => {
    if ((!hasClicked || description || title) && unavailableItem.parentItemId) refetch();
  }); //forces the parent item to update with the available modifiers

  if (hasClicked || !description || !title) return null;

  return (
    <Banner
      status="error"
      title={title}
      description={description}
      onClose={handleClose}
      actionText="Make new selection"
      onAction={handleMakeNewSelection}
    />
  );
};
