import React from 'react';
import { Center, Text, CenterProps } from '@chakra-ui/react';
import { useMerchant } from '@domain/merchants/queries/useMerchant';

export const OrderTypeSelectorHiddenReplacementBanner = (props: CenterProps) => {
  const { data } = useMerchant();

  // This works because this component will only show if only one or the other is available
  // ...meaning both pickup and delivery should never be available if this component mounts
  const orderTypeString = data?.orderTypes?.['pickup'] ? 'Pickup' : 'Delivery';

  return (
    <Center w="100%" p={1} bg="charcoal" {...props}>
      <Text fontWeight="semibold" fontSize="xs" color="white">
        {orderTypeString} only
      </Text>
    </Center>
  );
};
