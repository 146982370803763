import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { EOrderStatus } from '@olo-web/types/enums';

const filterItems = (itemsToFilter, filterValue) => itemsToFilter?.filter(filterValue);
const filterGuests = (guestsToFilter, filterValue) =>
  guestsToFilter?.map((guest) => {
    const newGuest = { ...guest };
    newGuest.items = filterItems(newGuest?.items, filterValue);
    return newGuest;
  });

export const useItemFiltering = () => {
  const { data: order } = useOrder();
  const showReserveSpecificStuff =
    order?.isReserveOrder && order?.groupOrderInfo?.status === EOrderStatus.PENDING;
  const submittedItems = showReserveSpecificStuff
    ? filterItems(order?.items, (item) => !item?.pending)
    : filterItems(order?.items, (item) => item?.sentDateTime !== null);
  const unsubmittedItems = showReserveSpecificStuff
    ? filterItems(order?.items, (item) => item?.pending)
    : filterItems(order?.items, (item) => item?.sentDateTime === null);

  const guestsWithSubmittedItems = showReserveSpecificStuff
    ? filterGuests(order?.guests, (item) => !item?.pending)
    : filterGuests(order?.guests, (item) => item?.sentDateTime !== null);

  const guestsWithUnsubmittedItems = showReserveSpecificStuff
    ? filterGuests(order?.guests, (item) => item?.pending)
    : filterGuests(order?.guests, (item) => item?.sentDateTime === null);

  return {
    submittedItems,
    unsubmittedItems,
    guestsWithSubmittedItems,
    guestsWithUnsubmittedItems,
  };
};
