import React, { useRef } from 'react';
import { useMount } from 'react-use';
import { useMenuGroupRibbon } from './hooks/useMenuGroupRibbon';
import { Box, Center, Flex, HStack, Skeleton } from '@chakra-ui/react';
import { Ribbon } from '@molecules/Ribbon';
import { MenuGroupListMenu } from './MenuGroupListMenu';
import { WideScreenContainer } from '@olo-web/components/atoms/WideScreenContainer';
import { OrderingUnavailableAlert } from '../../../../common';
import { useMenuDispatch } from '@olo-web/client-state';

export const MenuGroupRibbon = () => {
  const {
    groupRibbonItems: gRI,
    handleGroupSelect,
    selectedGroupId,
    searchText,
    isLoading,
    isIdle,
  } = useMenuGroupRibbon();
  const menuDispatch = useMenuDispatch();

  const ref = useRef<HTMLDivElement>(null);

  useMount(() => {
    menuDispatch({ type: 'SET_MENU_RIBBON_REF', payload: ref });
  });

  if (isIdle || isLoading) {
    const arr = new Array(4).fill(true);
    const randomNumber = Math.random() * 9999999999;
    return (
      <WideScreenContainer>
        <HStack
          spacing={6}
          alignItems="center"
          justifyContent="flex-start"
          h="44px"
          ml={{ base: 4, md: 10 }}
          w="100%"
        >
          {arr.map((_, index) => (
            <Skeleton key={`ribbon-loading-${randomNumber + index}`} w="148px" h="32px" />
          ))}
        </HStack>
      </WideScreenContainer>
    );
  }

  if (!gRI?.length && searchText?.length) {
    return null;
  }

  if (!gRI?.length) {
    return (
      <Center w="100%">
        <OrderingUnavailableAlert />
      </Center>
    );
  }

  return (
    <WideScreenContainer pos="relative">
      <Flex justify="space-between" align="center" h="44px" pos="relative" w="100%">
        <Ribbon refItem={ref} items={gRI} value={selectedGroupId} onSelect={handleGroupSelect} />
        <Box
          pos="absolute"
          right={{
            base: 8,
            md: 20,
          }}
          top={0}
          bottom="2px"
          w={12}
          bgGradient="linear(to-r, whiteAlpha.50, whiteAlpha.600)"
        />
        <Box pos="absolute" right={{ base: 1, md: 10 }} bottom="2px">
          <Flex>
            <MenuGroupListMenu groupPreviews={gRI} onSelect={handleGroupSelect} />
          </Flex>
        </Box>
      </Flex>
    </WideScreenContainer>
  );
};
