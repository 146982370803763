import React from 'react';
import { Avatar as ChakraAvatar, AvatarProps } from '@chakra-ui/react';

interface IAvatarProps extends AvatarProps {
  isUser?: boolean;
}

export const Avatar = ({ isUser, ...props }: IAvatarProps) => {
  const color = isUser ? 'primary.300' : 'blackAlpha.400';
  const sx = {};
  if (props.size === 'sm') {
    sx['& > div'] = {
      fontSize: '1rem',
    };
  }

  return (
    <ChakraAvatar
      bg="white"
      borderStyle="solid"
      borderWidth={isUser ? 2 : 1}
      color={color}
      fontWeight={isUser ? 600 : 400}
      borderColor={color}
      sx={sx}
      {...props}
    />
  );
};
