import React, { FC, useMemo } from 'react';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { VStack } from '@chakra-ui/react';
import { Banner } from '@olo-web/components/atoms/Banner';

type T = {
  isDineIn: boolean;
  hasSentItems: boolean;
  statusOrder: string;
  guestName: string;
  dineinPayLaterEnabled: boolean;
  isReserveOrder?: boolean;
  allItemsPreSent?: boolean;
  preAuthRequired: boolean;
};

const BagBodyBanner: FC<T> = ({
  isDineIn,
  hasSentItems,
  dineinPayLaterEnabled,
  statusOrder,
  guestName,
  preAuthRequired,
  isReserveOrder,
  allItemsPreSent,
}) => {
  const bannerInfo = useMemo(() => {
    if (isDineIn) {
      if (isReserveOrder) {
        if (allItemsPreSent) return;
        return {
          description:
            "Pre-ordering lets you start an order while you wait. The kitchen will begin preparing it once you're seated.",
          dataTestId: 'sit-and-fire-notification',
        };
      } else if (!hasSentItems && dineinPayLaterEnabled) {
        if (preAuthRequired)
          return {
            description:
              'Don’t worry about ordering everything all at once - we’ll prepare items as you send them.  To get started, we just need a card on file.',
            dataTestId: 'pre-auth-notification',
          };
        if (statusOrder !== EOrderStatus.IN_CHECKOUT) {
          return {
            description:
              'Don’t worry about ordering everything all at once - we’ll prepare items as you send them. You can pay the bill after you’ve finished ordering.',
            dataTestId: 'pay-later-notification',
          };
        }
      } else if (statusOrder === EOrderStatus.IN_CHECKOUT) {
        return {
          description: `${guestName} is covering the bill - sit tight and you’ll be redirected when they’re finished.`,
          dataTestId: 'guest-covering-bill-notification',
        };
      }
    }
  }, [
    isDineIn,
    hasSentItems,
    dineinPayLaterEnabled,
    statusOrder,
    guestName,
    isReserveOrder,
    allItemsPreSent,
    preAuthRequired,
  ]);

  if (bannerInfo?.description) {
    return (
      <VStack w="100%" spacing={4} paddingBottom={4}>
        <Banner description={bannerInfo.description} data-testid={bannerInfo.dataTestId} />
      </VStack>
    );
  }

  return null;
};

export default BagBodyBanner;
