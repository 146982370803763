import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { BagStar } from '../../../assets/images/BagStar.ui';

export function BagWithSentItems({ isReserveOrder = false }): JSX.Element {
  const sentItemText = isReserveOrder
    ? 'All your items have been pre-ordered, but you can still order more!'
    : 'All your items have been sent, but you can still order more!';
  return (
    <VStack
      mt={{ base: 0, lg: 40 }}
      px={4}
      spacing={4}
      data-testid="sent-bag-state"
      flexDir="row"
      mb={4}
    >
      <BagStar w="120px" h="100%" paddingRight="2.5" />
      <Text textStyle="h5" textAlign="left" paddingLeft="1.5" marginTop="0px !important">
        {sentItemText}
      </Text>
    </VStack>
  );
}
