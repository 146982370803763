import React, { FC } from 'react';
import { Flex, Text, Image, Button, HStack } from '@chakra-ui/react';
import { useMerchantImages } from '@domain/merchants/hooks/useMerchantImages';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { WideScreenContainer } from '@olo-web/components/atoms/WideScreenContainer';
import { TScrollSensitiveNavBar } from '../..';
import { MenuHeaderButtonsDesktop } from '../../../MenuHeaderButtons';
import { useSensitiveScrollNavBar } from '../../../..';
import { useModalDispatch } from '@olo-web/client-state';
import { EModalTypes } from '@olo-web/types/enums';
import {
  useEstimatedTimeText,
  useIsThirdPartyDelivery,
  useOrderTypeDisplayName,
} from '@olo-web/utils/common/hooks';
import { useEstimateDelivery } from '@olo-web/domain/deliveryService/queries/useEstimateDelivery';

export const NavBarDetailsDesktop: FC<TScrollSensitiveNavBar> = ({ show }) => {
  const modalDispatch = useModalDispatch();
  const { data: order } = useOrder();
  useSensitiveScrollNavBar({
    show,
  });
  const { logoUrl, logoAlt } = useMerchantImages();
  const orderTypeDisplayName = useOrderTypeDisplayName();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { data: deliveryEstimate } = useEstimateDelivery();
  const estimatedTimeText = useEstimatedTimeText(
    isThirdPartyDelivery ? deliveryEstimate?.deliveryTime : order?.orderDateTime
  );

  const handleClick = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      payload: { modalKey: EModalTypes.ORDER_TIME },
    });
  };

  return (
    <WideScreenContainer borderBottom="1px solid" borderBottomColor="blackAlpha.100">
      <Flex align="center" pl={10} pr={10} justify="space-between" h="64px">
        <Flex align="center" justify="center">
          {logoUrl && (
            <Image
              src={logoUrl}
              alt={logoAlt}
              maxH="40px"
              maxW="80px"
              mr={4}
              data-testid="dynamic-header-location-logo"
            />
          )}
          <HStack spacing={1} fontSize="sm" color="blackAlpha.900">
            <Text>{orderTypeDisplayName}</Text>
            <Text>•</Text>
            <Button
              variant="ghost"
              px={0}
              colorScheme="blackAlpha"
              size="sm"
              fontWeight="normal"
              textDecor="underline"
              onClick={handleClick}
            >
              {estimatedTimeText}
            </Button>
          </HStack>
        </Flex>
        <MenuHeaderButtonsDesktop show={show} isInNavBar={true} />
      </Flex>
    </WideScreenContainer>
  );
};
