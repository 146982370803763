import React from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  Flex,
  Button,
  Text,
} from '@chakra-ui/react';
import { useBagBar } from '../hooks';
import { BagBody } from '../BagBody';
import { BagIconForButton } from '@molecules/BagIconForButton';

export const BagButtonAndPopover = ({ show }) => {
  const {
    order,
    open,
    handleToggle,
    orderLoading,
    goToCheckout,
    productCount,
    routerLoading,
    totalQuantity,
    isValidatingOrder,
    bagButtonText,
    bagTitle,
  } = useBagBar();

  return (
    <Popover preventOverflow strategy="fixed" placement="bottom-end" isOpen={open}>
      <PopoverTrigger>
        <Button
          colorScheme="primary"
          variant={open ? 'outline' : 'solid'}
          onClick={handleToggle}
          boxSizing="border-box"
          border="1px solid"
          borderColor="primary.300"
          minW="153px"
          display="flex"
          fontSize="lg"
          alignItems="center"
          justifyContent="space-between"
          _focus={{
            borderColor: 'primary.300',
          }}
          {...(show && { 'data-testid': 'your-bag-button' })}
        >
          <BagIconForButton
            open={open}
            productCount={productCount}
            totalQuantity={totalQuantity}
            buttonConfig={{ h: 5, w: 5 }}
            small
          />
          <Text as="h2" textStyle="h3">
            {bagTitle}
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        maxH="82vh"
        w="360px"
        boxShadow="0 0 10px rgba(0,0,0,0.1)"
        {...(show && { 'data-testid': 'your-bag-modal' })}
      >
        <PopoverArrow />
        <PopoverBody
          overflowY="scroll"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
              width: '0px',
            },
          }}
        >
          {show && <BagBody order={order} isLoading={orderLoading} />}
        </PopoverBody>
        {!!productCount && (
          <PopoverFooter border="none" p={0}>
            <Flex align="center" mt="auto" p={4}>
              <Button
                w="100%"
                colorScheme="primary"
                size="lg"
                onClick={goToCheckout}
                {...(show && { 'data-testid': 'continue-to-checkout-button' })}
                isLoading={isValidatingOrder || routerLoading}
              >
                {bagButtonText}
              </Button>
            </Flex>
          </PopoverFooter>
        )}
      </PopoverContent>
    </Popover>
  );
};
