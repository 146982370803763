import React, { memo } from 'react';
import { useCustomer } from '@olo-web/domain/customer';
import { LoginButton } from '@olo-web/components/atoms/LoginButton';
import {
  SkeletonCircle,
  ButtonProps,
  AvatarProps,
  IconProps,
  MenuButtonProps,
} from '@chakra-ui/react';
import { CustomerAvatarMenu } from '../CustomerAvatarMenu';
import { DisruptionIconButton } from '@olo-web/components/molecules/DisruptionIconButton/DisruptionIconButton';

type TCustomerOrLogin = {
  buttonProps?: ButtonProps;
  buttonIconProps?: IconProps;
  avatarProps?: AvatarProps;
  avatarMenuButtonProps?: MenuButtonProps & { 'data-testid'?: string };
};

const CustomerOrLoginComponent = ({
  buttonProps = {},
  buttonIconProps = {},
  avatarProps = {},
  avatarMenuButtonProps = {},
}: TCustomerOrLogin) => {
  const { data: customer, isLoading } = useCustomer();

  if (isLoading) {
    return <SkeletonCircle zIndex="3" />;
  }

  if (!customer?.customerId)
    return (
      <>
        <DisruptionIconButton display={{ base: 'inline', md: 'none' }} />
        <LoginButton {...buttonProps} iconProps={buttonIconProps} zIndex="3" />
      </>
    );

  return (
    <CustomerAvatarMenu avatarProps={avatarProps} avatarMenuButtonProps={avatarMenuButtonProps} />
  );
};

export const CustomerOrLogin = memo(CustomerOrLoginComponent);
