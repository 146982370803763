import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BagIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 30" fill="none" {...props}>
      <path
        d="M19.8395 7.90234H3.57201C3.04992 7.90234 2.61568 8.304 2.57505 8.82451L1.16829 26.8439C1.07754 28.0063 1.9963 28.9995 3.16222 28.9995H20.2493C21.4152 28.9995 22.334 28.0063 22.2432 26.8439L20.8365 8.82451C20.7959 8.304 20.3616 7.90234 19.8395 7.90234Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.58789 11.294V5.24238C7.58789 4.11723 8.02171 3.03816 8.79391 2.24256C9.56611 1.44696 10.6134 1 11.7055 1C12.7976 1 13.8449 1.44696 14.6171 2.24256C15.3893 3.03816 15.8231 4.11723 15.8231 5.24238V11.294"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
};
