import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useMount } from 'react-use';
import { Wrap, Box, Flex, Text, HStack } from '@chakra-ui/react';
import { Avatar } from '@olo-web/components/atoms/Avatar';
import { useModalDispatch, useSavedDineInContextState } from '@olo-web/client-state';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { EModalTypes } from '@olo-web/types/enums';
import { useSendEvent, useGetFullTableIdAndNumber } from '@olo-web/utils/common/hooks';
import { EAnalyticsEventNames, EGALocations } from '@olo-web/types/enums';
import { useOrderIdFeatureFlagEnabled } from '@olo-web/utils/common/hooks/useOrderIdFeatureFlagEnabled';
import { useMerchantOrderTypeDetails } from '@domain/merchants/queries/useMerchantOrderTypeDetails';
interface TableAndCallNumberInfo {
  tableNumber?: string;
  qrCallNumber?: string;
}

const TableAndCallNumberHeader = ({ tableNumber, qrCallNumber }: TableAndCallNumberInfo) => {
  const fullTableIDAndNumber = useGetFullTableIdAndNumber();
  return (
    <HStack>
      {tableNumber && (
        <Text fontWeight={400} flexDirection="row" alignItems="flex-start" pr={1}>
          {fullTableIDAndNumber}
        </Text>
      )}
      {/* VQA wants this pipe instead of the stack divider */}
      {tableNumber && qrCallNumber && <Text fontWeight="500">|</Text>}
      {qrCallNumber && (
        <Text fontWeight={600} flexDirection="row" alignItems="flex-start">
          Order {qrCallNumber}
        </Text>
      )}
    </HStack>
  );
};

const JoinedTableHeaderComponent: ForwardRefRenderFunction<any, any> = (props, ref) => {
  const { data: order, refetch: refetchOrder } = useOrder();
  const savedDineInState = useSavedDineInContextState();
  const modalDispatch = useModalDispatch();
  const thisGuest = order?.guests?.find((g) => g.id === savedDineInState?.guest?.id);
  const hasSentItems = thisGuest?.items?.some((i) => i.sentDateTime !== null);
  const { sendEvent } = useSendEvent();
  const orderIdFeatureFlagEnabled = useOrderIdFeatureFlagEnabled();
  const { data: orderTypeDetails } = useMerchantOrderTypeDetails();

  useMount(() => {
    if (order?.isReserveOrder) {
      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.DINE_IN,
            action: 'click',
            object: EAnalyticsEventNames.SIT_FIRE_VISIT,
          },
          eventMetadata: {
            guestId: order?.groupOrderInfo?.statusGuestId,
          },
        },
      });
    }
  });

  const openLeaveTable = () => {
    refetchOrder();
    if (hasSentItems) return;

    modalDispatch({
      type: 'OPEN_MODAL',
      payload: {
        modalKey: EModalTypes.LEAVE_TABLE,
      },
    });
  };

  const handleInviteOthersClick = () => {
    if (orderIdFeatureFlagEnabled) {
      modalDispatch({
        type: 'OPEN_MODAL',
        payload: {
          modalKey: EModalTypes.INVITE_OTHERS,
        },
      });

      sendEvent(EAnalyticsEventNames.DEFAULT, {
        googleAnalytics: {
          eventInfo: {
            location: EGALocations.DINE_IN,
            action: 'click',
            object: EAnalyticsEventNames.SHARE_SHEET_VIEW,
          },
          eventMetadata: {
            orderId: order?.id,
            tableId: order?.tableNumber,
          },
        },
      });
    } else {
      let url = window.location.href.split('?')[0];
      url += `?tableNumber=${encodeURIComponent(savedDineInState?.table?.number)}`;
      const data = {
        title: window.document.title,
        url,
      };
      navigator.share(data);
    }
  };

  return (
    <Box ref={ref} p={6} padding="4">
      <Flex justifyContent="space-between" alignItems="left" flexDirection="column">
        <Flex flexDirection="row" paddingBottom="4" justifyContent={'space-between'}>
          <TableAndCallNumberHeader
            qrCallNumber={order?.qrCallNumber}
            tableNumber={order?.tableNumber}
          />
          {orderTypeDetails?.groupOrderingEnabled && (
            <Box textAlign="right" flex={1}>
              <Text
                fontWeight="normal"
                size="sm"
                width="auto"
                color="primary.300"
                fontSize="0.875rem"
                as="button"
                onClick={handleInviteOthersClick}
              >
                <Text as="u">Invite others</Text>
              </Text>
            </Box>
          )}
        </Flex>
        {order?.guests?.length === 1 && (
          <Flex justifyContent="center" flex={1}>
            <Avatar
              name={savedDineInState?.guest?.name?.split(' ')[0]}
              cursor={hasSentItems ? 'not-allowed' : 'pointer'}
              as="button"
              isUser
              size="sm"
              aria-label={savedDineInState?.guest?.name?.split(' ')[0]}
              data-testid="dinein-user-icon"
              onClick={openLeaveTable}
            />
          </Flex>
        )}
      </Flex>
      {order?.guests?.length > 1 && (
        <Wrap spacing={3} justify="center" mt={1}>
          <Avatar
            name={savedDineInState?.guest?.name?.split(' ')[0]}
            cursor={hasSentItems ? 'not-allowed' : 'pointer'}
            as="a"
            isUser
            size="sm"
            data-testid="dinein-user-icon"
            onClick={openLeaveTable}
          />
          {order?.guests?.map((guest) => {
            if (guest?.id !== savedDineInState?.guest?.id)
              return <Avatar key={guest?.id} size="sm" name={guest?.name?.split(' ')[0]} />;
          })}
        </Wrap>
      )}
    </Box>
  );
};

export const JoinedTableHeader = forwardRef(JoinedTableHeaderComponent);
