export interface ICompareResult {
  color?: string;
  bool?: boolean | null;
}

const convertToWholeNumber = (num: number | string) => {
  if (typeof num === 'string') {
    return Number(num.split('.').join(''));
  }
  return num;
};

const digit = (numA: number) => (numB: number) => Math.floor(numB / Math.pow(10, numA)) % 10;

export const convertToColorAndAnimationArray = (
  valueToFixed: number | string,
  debouncedValue: number | string,
  animationScheme?: string | null,
  baseColor?: string
): ICompareResult[] => {
  const valToFixedNum = convertToWholeNumber(valueToFixed);
  const debouncedValNum = convertToWholeNumber(debouncedValue);

  let pColor: string | undefined;
  let nColor: string | undefined;

  switch (animationScheme) {
    case 'primary':
      pColor = 'primary.400';
      nColor = 'danger.400';
      break;
    case 'base':
      pColor = 'blackAlpha.500';
      nColor = 'danger.400';
      break;
    case 'green-red':
      pColor = 'success.400';
      nColor = 'danger.400';
      break;
    default:
      break;
  }

  const color = valToFixedNum > debouncedValNum ? pColor || baseColor : nColor || baseColor;

  return Array.from(
    {
      length:
        valToFixedNum === debouncedValNum
          ? 1
          : Math.ceil(Math.log10(Math.max(valToFixedNum, debouncedValNum))),
    },
    (_, i) => {
      const [digitValToFixed, digitDebouncedVal] = [valToFixedNum, debouncedValNum].map(digit(i));

      return digitValToFixed === digitDebouncedVal
        ? { color: color, bool: null }
        : { color: color, bool: digitValToFixed > digitDebouncedVal };
    }
  ).reverse();
};
