import React, { useRef, LegacyRef } from 'react';
import { HStack, VStack, Button, useMergeRefs } from '@chakra-ui/react';
import { AnimateSharedLayout } from 'framer-motion';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { useInView } from 'react-intersection-observer';

function RibbonTab({
  item,
  isSelected,
  onClick,
}: {
  item: IRibbonItem;
  isSelected: boolean;
  onClick: (group: IRibbonItem) => void;
}): JSX.Element {
  const tabRef = useRef<HTMLElement>(null);
  const { inView, ref } = useInView();
  const refs = useMergeRefs(tabRef, ref);
  const handleClick = (): void => {
    onClick(item);
    if (isSelected && !inView) {
      tabRef?.current?.scrollIntoView();
    }
  };

  return (
    <VStack pos="relative" ref={refs}>
      <Button
        variant="tertiary"
        color={isSelected ? 'black' : 'blackAlpha.500'}
        _hover={{ color: 'primary.350' }}
        fontWeight={isSelected ? '500' : '300'}
        h="42px"
        onClick={handleClick}
        data-testid="menu-item-group"
        aria-controls={`group-menu-${item.value}`}
        aria-haspopup="false"
        role="link"
        aria-current={isSelected ? 'location' : undefined}
      >
        {item.label}
      </Button>
      {isSelected && (
        <MotionBox
          layoutId="underline"
          w="100%"
          h="4px"
          bg="primary.350"
          pos="absolute"
          bottom={{ base: 0, md: '1px' }}
          borderRadius="5px 5px 0 0"
        />
      )}
    </VStack>
  );
}

export function Ribbon({
  items = [],
  onSelect,
  value,
  refItem,
}: {
  items: IRibbonItem[];
  onSelect: (item: IRibbonItem) => void;
  value?: any;
  refItem: LegacyRef<HTMLDivElement>;
}): JSX.Element {
  return (
    <HStack
      pos="absolute"
      bottom={0}
      right={{
        base: 8,
        md: 20,
      }}
      left={{
        base: 0,
        md: 8,
      }}
      overflowX="scroll"
      h="44px"
    >
      <HStack
        spacing={2}
        px={2}
        pos="relative"
        h="44px"
        data-testid="menu-item-group-list"
        ref={refItem}
      >
        <AnimateSharedLayout>
          {items.map((item) => (
            <RibbonTab
              key={item.value}
              item={item}
              onClick={onSelect}
              isSelected={value === item.value}
            />
          ))}
        </AnimateSharedLayout>
      </HStack>
    </HStack>
  );
}
