import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { EmptyBagImage } from '@olo-web/assets/images/EmptyBagImage.ui';
import { ProductUnavailability } from '@olo-web/components/molecules/ProductOrderedInfo/ProductUnavailability';

export function EmptyBag(): JSX.Element {
  return (
    <VStack mt={{ base: 8, lg: 40 }} mb={8} px={4} spacing={4} data-testid="empty-bag-state">
      <ProductUnavailability />
      <EmptyBagImage w="120px" h="150px" />
      <Text textStyle="h3" textAlign="center">
        Your bag is empty
      </Text>
      <Text textStyle="p" textAlign="center" lineHeight="22px">
        Add some items to get started
      </Text>
    </VStack>
  );
}
