import { useMenuDispatch, useMenuState } from '@olo-web/client-state';
import { useMenuFilteredBySearch } from '@olo-web/domain/menu/queries';
import { scrollElementToTop } from '@olo-web/utils/common/functions';
import { useScreenSizeState } from '@olo-web/client-state';

type TUseMenuGroupRibbon = {
  groupRibbonItems: IRibbonItem[];
  handleGroupSelect: (groupPreview: IRibbonItem) => void;
  handleMenuRibbonFocus: () => void;
  selectedGroupId: string | null;
  searchText: string | null;
  isLoading: boolean;
  isIdle: boolean;
};

export const useMenuGroupRibbon = (): TUseMenuGroupRibbon => {
  const menuDispatch = useMenuDispatch();
  const { groups, isLoading, isIdle } = useMenuFilteredBySearch();
  const { searchText, selectedGroupId, scrollBoxRef, menuRibbonRef } = useMenuState();
  const { isMdDown } = useScreenSizeState();
  const yOffset = isMdDown ? 316 : 140; // values need to change as nav bar height changes

  const groupRibbonItems: IRibbonItem[] = groups.map((g) => ({ label: g?.name, value: g.id }));

  const handleGroupSelect = (groupPreview: IRibbonItem) => {
    menuDispatch({
      type: 'UPDATE_SELECTED_GROUP',
      payload: groupPreview.value,
    });
    const elem = document?.getElementById(`group-menu-${groupPreview.value}`);
    if (!elem) return;
    scrollElementToTop({
      element: elem,
      offset: yOffset,
      rootElement: scrollBoxRef,
    });

    // After scrolling, move focus to the heading element inside the group
    // Using setTimeout to ensure scrolling completes first
    setTimeout(() => {
      const headingElem = elem.querySelector('[data-testid="menu-item-group-name"]');
      if (headingElem && headingElem instanceof HTMLElement) {
        headingElem.focus({ preventScroll: true });
      }
    }, 100);
  };

  const handleMenuRibbonFocus = () => {
    (menuRibbonRef?.current.firstChild as HTMLElement).focus({ preventScroll: true });
  };

  return {
    groupRibbonItems,
    handleGroupSelect,
    selectedGroupId,
    searchText,
    isLoading,
    isIdle,
    handleMenuRibbonFocus,
  };
};
