import React, { useMemo, useRef, useState } from 'react';
import { Text, Button, useDisclosure, AlertDescription } from '@chakra-ui/react';
import { Banner } from '@olo-web/components/atoms/Banner';
import { useCustomerMessage } from '@domain/merchants/hooks/useCustomerMessage';

export const CustomerMessageBanner = () => {
  const customerMessage = useCustomerMessage();
  const { isOpen: isFullText, onToggle: toggleText } = useDisclosure();
  const [isClosed, setIsClosed] = useState(false);
  const textRef = useRef(null);

  const message = useMemo(() => {
    setIsClosed(false);
    return customerMessage;
  }, [customerMessage]);

  const isTruncatedMessage = useMemo(() => (message ? message?.length > 64 : undefined), [message]);

  const handleButtonClick = () => {
    toggleText();
    if (!isFullText) {
      textRef.current.focus();
    }
  };

  if (!message || isClosed) return null;

  return (
    <Banner
      id="customer-alert-message"
      onClose={() => setIsClosed(true)}
      data-testid="customer-alert-message"
      role="status"
      aria-live="polite"
      description={
        <>
          <AlertDescription>
            <Text
              w="98%"
              textStyle="p"
              noOfLines={!isFullText && isTruncatedMessage ? 2 : undefined}
            >
              {message}
            </Text>
            {isTruncatedMessage && (
              <Button
                data-testid="show-more-show-less-button"
                color="black"
                textStyle="link"
                variant="link"
                onClick={handleButtonClick}
              >
                {isFullText || !isTruncatedMessage ? 'Show less' : 'Show more'}
              </Button>
            )}
          </AlertDescription>
        </>
      }
    />
  );
};
