import { Flex, TextProps, Text } from '@chakra-ui/react';
import { MotionBox } from '@olo-web/components/atoms/motion/MotionBox.ui';
import { useDebounce } from '@olo-web/utils/common/hooks';
import React, { useMemo } from 'react';
import { convertToColorAndAnimationArray, convertToDigitsArray } from './functions';
import { MotionProps } from 'framer-motion';

const DURATION = 0.5;

type AnimatedNumberDisplayProps = TextProps & MotionProps;

interface IAnimatedNumberDisplayProps extends AnimatedNumberDisplayProps {
  value: number | string;
  animationScheme?: 'primary' | 'base' | 'green-red' | null | undefined;
  toFixed?: number;
  leadingChar?: string;
}

interface IAnimatedDigitProps extends Omit<IAnimatedNumberDisplayProps, 'value'> {
  digit: string;
  bool?: boolean | null;
}

const Digit = ({ digit, color, ...rest }) => {
  const debouncedDigit = useDebounce(digit, 350);
  const displayDigit = debouncedDigit || digit;

  return (
    <Text color={color} {...rest} textAlign="center">
      {displayDigit}
    </Text>
  );
};

const AnimatedDigit = ({ digit, bool, color, ...rest }: IAnimatedDigitProps) => {
  const opacity = [1, 0, 0, 1];
  const variants = {
    up: {
      y: ['0px', '8px', '-8px', '0px'],
      opacity,
      transition: {
        duration: DURATION,
      },
    },
    down: {
      y: ['0px', '-8px', '8px', '0px'],
      opacity,
      transition: {
        duration: DURATION,
      },
    },
    stationary: {
      y: '0px',
      opacity: 1,
      transition: {
        duration: DURATION,
      },
    },
  };

  const chooseVariant = (val: boolean | null) => {
    if (val === true) return 'up';
    if (val === false) return 'down';
    return 'stationary';
  };

  const { w, width, ...props } = rest;
  const displayWidth = useMemo(() => {
    if (!(w || width)) return null;
    switch (digit) {
      case '.':
        return '0.4rem';
      case '$':
        return 'auto';
      default:
        return w || displayWidth || 'auto';
    }
  }, [digit, w, width]);

  return (
    <MotionBox
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      color={color}
      w={displayWidth}
      variants={variants}
      initial="stationary"
      animate={chooseVariant(!!bool)}
      {...props}
    >
      <Digit color={color} digit={digit} {...rest} />
    </MotionBox>
  );
};

export const AnimatedNumberDisplay = ({
  value = 0,
  animationScheme,
  toFixed = 0,
  leadingChar,
  ...rest
}: IAnimatedNumberDisplayProps) => {
  const valueToFixed = Number(value || 0).toFixed(toFixed);
  const debouncedValue = useDebounce(valueToFixed, DURATION * 500);
  const digitComponents = useMemo(() => {
    const arr = convertToColorAndAnimationArray(
      valueToFixed,
      debouncedValue,
      animationScheme,
      rest?.color as string
    );
    const digitsArray = convertToDigitsArray(valueToFixed, arr);
    return digitsArray
      .reverse()
      .map((item, index) => <AnimatedDigit key={`digit-${index}`} {...rest} {...item} />);
  }, [debouncedValue, animationScheme, rest, valueToFixed]);
  return (
    <Flex align="center" justify="center" flexDir="row-reverse">
      {digitComponents}
      {leadingChar && (
        <Text {...rest} color={digitComponents[0]?.props.color}>
          {leadingChar}
        </Text>
      )}
    </Flex>
  );
};
